// /car-listing-frontend/src/components/LoanApplication.js
import React, { useState, useRef, useEffect } from 'react';
import {
    Box, Typography, Paper, Alert, Fade, TextField, IconButton, CircularProgress, SvgIcon, Chip, Avatar
} from '@mui/material';
import { styled, keyframes } from '@mui/material/styles';
import axios from 'axios';
import ReactMarkdown from 'react-markdown';
// Icons
import SendIcon from '@mui/icons-material/Send';
import AddIcon from '@mui/icons-material/Add';
import SearchIcon from '@mui/icons-material/Search';
import LightbulbOutlinedIcon from '@mui/icons-material/LightbulbOutlined';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import SyncProblemIcon from '@mui/icons-material/SyncProblem';

// Import ParticleBackground
import ParticleBackground from './ParticlesBackground'; // Adjust path if needed

// --- Styling ---

const ChatContainer = styled(Box)({
    display: 'flex',
    flexDirection: 'column',
    height: '100vh',
    backgroundColor: '#0b0b09', // Set base background color
    color: '#e0e0e0',
    position: 'relative',
    overflow: 'hidden',
});

// Style for the content overlay above particles
const ContentOverlay = styled(Box)({
    position: 'relative',
    zIndex: 1,
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    backgroundColor: 'transparent', // REMOVED background color to show particles
});


const Header = styled(Box)(({ theme }) => ({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: theme.spacing(1, 2),
    borderBottom: '1px solid rgba(255, 255, 255, 0.1)',
    flexShrink: 0,
    backgroundColor: 'transparent', // Make header transparent
    // Removed backdropFilter
}));

const HeaderTitle = styled(Typography)({
    display: 'flex',
    alignItems: 'center',
    fontSize: '1rem',
    fontWeight: 500,
    cursor: 'pointer',
});

const TemporaryChip = styled(Chip)(({ theme }) => ({
    backgroundColor: 'rgba(255, 255, 255, 0.1)', // Keep chip background for visibility
    color: '#bdbdbd',
    fontSize: '0.75rem',
    height: '24px',
    '.MuiChip-icon': {
        color: '#bdbdbd',
        marginLeft: '8px',
        marginRight: '-4px',
        fontSize: '1rem',
    },
    '.MuiChip-label': {
        paddingLeft: '8px',
        paddingRight: '4px',
    },
}));

const ChatHistory = styled(Box)({
    flexGrow: 1,
    overflowY: 'auto',
    padding: '24px',
    display: 'flex',
    flexDirection: 'column',
    // Custom scrollbar
    '&::-webkit-scrollbar': { width: '6px' },
    '&::-webkit-scrollbar-track': { background: 'transparent' }, // Transparent track
    '&::-webkit-scrollbar-thumb': { background: '#424242', borderRadius: '3px' },
    '&::-webkit-scrollbar-thumb:hover': { background: '#555' },
});

const InitialPromptContainer = styled(Box)({
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    paddingBottom: '10vh',
});

const InputAreaContainer = styled(Box)(({ theme }) => ({
    padding: theme.spacing(1.5, 2, 1, 2),
    flexShrink: 0,
    backgroundColor: 'transparent', // Make input area container transparent
    borderTop: '1px solid rgba(255, 255, 255, 0.1)', // Keep separator line
    maxWidth: '800px',
    width: '100%',
    margin: '0 auto',
    boxSizing: 'border-box',
}));

// Keep InputWrapper with a background for visual separation of the text field
const InputWrapper = styled(Paper)(({ theme }) => ({
    display: 'flex',
    alignItems: 'flex-end',
    padding: theme.spacing(0.5, 0.5, 0.5, 1.5),
    borderRadius: '16px',
    backgroundColor: '#1e1e1e', // Use a dark color slightly different from #0b0b09
    border: '1px solid #333', // Adjust border color if needed
    boxShadow: '0 2px 10px rgba(0,0,0,0.3)',
    '&:focus-within': {
       borderColor: '#444',
    },
}));

const StyledTextField = styled(TextField)({
    flexGrow: 1,
    '& .MuiInputBase-root': {
        padding: 0,
        color: '#e0e0e0',
        fontSize: '0.95rem',
        lineHeight: 1.5,
    },
    '& .MuiOutlinedInput-notchedOutline': {
        border: 'none',
    },
    '& .MuiInputBase-input::placeholder': {
       color: '#888',
       opacity: 1,
    },
    '& .MuiInputBase-inputMultiline': {
        padding: '10px 0',
        maxHeight: '200px',
        overflowY: 'auto !important',
        scrollbarWidth: 'thin',
        scrollbarColor: '#666 #303030', // Scrollbar thumb/track for text area
        '&::-webkit-scrollbar': { width: '6px' },
        '&::-webkit-scrollbar-track': { background: '#1e1e1e' }, // Match InputWrapper background
        '&::-webkit-scrollbar-thumb': { background: '#555', borderRadius: '3px' },
        '&::-webkit-scrollbar-thumb:hover': { background: '#666' },
    },
});

const SendButtonWrapper = styled(Box)(({ theme }) => ({
  marginLeft: theme.spacing(1),
  marginBottom: theme.spacing(0.5),
}));

const StyledIconButton = styled(IconButton)(({ theme, disabled }) => ({
    backgroundColor: disabled ? 'transparent' : '#424242',
    color: disabled ? '#666' : '#e0e0e0',
    padding: '6px',
    '&:hover': {
       backgroundColor: disabled ? 'transparent' : '#555',
    },
}));

// Make suggestion chips slightly transparent too
const SuggestionChip = styled(Chip)(({ theme }) => ({
    backgroundColor: 'rgba(255, 255, 255, 0.05)', // More transparent background
    color: '#ccc',
    border: '1px solid #444', // Slightly lighter border
    margin: theme.spacing(0.5, 0.5),
    fontSize: '0.8rem',
    height: '30px',
    '&:hover': {
        backgroundColor: 'rgba(255, 255, 255, 0.1)',
        borderColor: '#666',
    },
    '.MuiChip-icon': {
        color: '#aaa',
        marginLeft: '6px',
        marginRight: '-4px',
        fontSize: '1rem',
    },
    '.MuiChip-label': {
        paddingLeft: '10px',
        paddingRight: '10px',
    },
}));


// Make message boxes transparent, maybe add a very subtle border for definition
const MessageBox = styled(Box)(({ theme, type }) => ({
  maxWidth: '100%',
  alignSelf: 'center',
  width: 'fit-content', // Ensures box shrinks to content unless maxWidth is hit
  marginBottom: theme.spacing(2.5),
  padding: theme.spacing(1.5, 2),
  borderRadius: '12px',
  // Use distinct dark grey backgrounds again
  backgroundColor: type === 'user'
      ? 'rgba(40, 40, 40, 0.9)' // Dark grey for user (slightly transparent)
      : 'rgba(50, 50, 50, 0.9)', // Slightly lighter grey for AI (slightly transparent)
  // Add a subtle border that complements the background
  border: `1px solid ${type === 'user' ? 'rgba(60, 60, 60, 0.9)' : 'rgba(70, 70, 70, 0.9)'}`,
  wordBreak: 'break-word',
  whiteSpace: 'pre-wrap',
  fontFamily: type === 'user' ? 'monospace' : 'inherit',
  fontSize: type === 'user' ? '0.85rem' : '0.95rem',
  lineHeight: 1.6,
  color: '#e0e0e0', // Ensure text color is still readable

  // Markdown specific styles
  '& strong': { color: '#b3e5fc', fontWeight: 600 },
  '& p': { margin: theme.spacing(0, 0, 1, 0) },
  '& ul, & ol': { paddingLeft: '25px', margin: theme.spacing(1, 0) },
  '& li': { marginBottom: theme.spacing(0.75) },
}));

// --- Component ---

const LoanApplication = () => {
    // State and functions (handleJsonChange, useEffect, handleSubmit, handleKeyPress) remain the same
    const [loading, setLoading] = useState(false);
    const [jsonInput, setJsonInput] = useState(JSON.stringify({
      "монгол_банкны_өгөгдөл": {
        "одоогийн_зээлүүд": 1,
        "төлөгдөөгүй_өр": 5000000,
        "өмнөх_дефолтууд": 0,
        "зээл_төлөлтийн_тогтвортой_байдал": "маш сайн",
        "хадгаламж": 3000000,
        "итгэлцэл": 0,
      },
      "нийгмийн_даатгалын_өгөгдөл": {
        "сарын_цалин": 2000000,
        "ажил_эрхлэлтийн_хугацаа": "5 жил",
        "өсөлт/бууралт": "жил бүр өссөн",
        "ажил_эрхлэлтийн_тогтвортой_байдал": "тогтмол, завсаргүй",
        "нийгмийн_даатгал_төлсөн": true
      },
      "дансны_хуулга_өгөгдөл": {
        "нийт_хамарсан_хугацаа": "2024 оны 9 сараас 2025 оны 3 сар хүртэл 7 сарын хугацаа",
        "дундаж_сарын_орлого": 7000000,
        "орлогын_хэлбэлзэл": "тогтмол",
        "орлого_тасалдсан_сарууд": false,
        "зардлын_хэв_маяг": {
          "тогтмол_бизнесийн_зардлууд": 3000000,
          "Toп-3 зардлууд": "түрээс: 1500000, хүнс: 800000, такси: 500000",
          "сэжигтэй_гүйлгээ_илрүүлсэн": true,
          "шөнийн цагийн гүйлгээ": 2000000,
          "мөрийтэй тоглоомчин байх магадлал": "15%"
        }
      },
      "гэмт хэргийн түүх": {
        "гэмт хэргийн түүх": false,
        "төлөгдөөгүй татвар, төлбөр болон торгууль": 20000,
        "шүүхийн маргаан": false,
      },
      "барьцаа_хөрөнгийн_үнэлгээний_өгөгдөл": {
        "хөрөнгийн_төрөл": "машин",
        "үнэлгээ": "50000000"
      },
      "Risk_Score": 0.43,
      "хүссэн_зээлийн_хэмжээ": 2500000
    }, null, 2));
    const [error, setError] = useState(null);
    const [messages, setMessages] = useState([]);
    const chatHistoryRef = useRef(null);

    const handleJsonChange = (e) => {
        setJsonInput(e.target.value);
        setError(null);
    };

    useEffect(() => {
        if (chatHistoryRef.current) {
            chatHistoryRef.current.scrollTop = chatHistoryRef.current.scrollHeight;
        }
    }, [messages]);

    const handleSubmit = async () => {
        let userData;
        setError(null);

        if (!jsonInput.trim()) {
             setError("Input cannot be empty.");
             setMessages(prev => [...prev, { type: 'error', content: "Input cannot be empty." }]);
             return;
        }

        try {
            userData = JSON.parse(jsonInput);
        } catch (parseError) {
             const errText = `Invalid JSON format: ${parseError.message}`;
             setError(errText);
             setMessages(prev => [...prev, { type: 'error', content: errText }]);
             return;
        }

        setMessages(prev => [...prev, { type: 'user', content: jsonInput }]);
        setJsonInput('');
        setLoading(true);

        try {
            const response = await axios.post('https://softmax.mn/api/process-loan/', {
                user_input: userData
            });

            if (response.data && typeof response.data.analysis !== 'undefined') {
                setMessages(prev => [...prev, { type: 'ai', content: response.data.analysis }]);
            } else {
                const errText = 'Received an unexpected response format from the server.';
                setError(errText);
                setMessages(prev => [...prev, { type: 'error', content: errText }]);
                console.warn('Unexpected API response:', response.data);
            }

        } catch (apiError) {
            let message = 'An unexpected error occurred.';
            if (axios.isAxiosError(apiError)) {
                message = apiError.response
                    ? `Server Error ${apiError.response.status}: ${apiError.response.data?.detail || apiError.message}`
                    : `Network Error: ${apiError.message}`;
                console.error('API Error:', apiError.response || apiError.request || apiError.message);
            } else {
                message = apiError.message || message;
                console.error('Unexpected Error:', apiError);
            }
             setError(message);
             setMessages(prev => [...prev, { type: 'error', content: message }]);
        } finally {
            setLoading(false);
        }
    };

    const handleKeyPress = (e) => {
      if (e.key === 'Enter' && !e.shiftKey) {
        e.preventDefault();
        if (!loading && jsonInput.trim()) {
            handleSubmit();
        }
      }
    };

    return (
        <ChatContainer>
            {/* Particle Background Layer */}
             <Box
                sx={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    zIndex: 0, // Behind the content
                }}
            >
                <ParticleBackground />
            </Box>

            {/* Content Overlay Layer */}
            <ContentOverlay>
                {/* Header */}
                <Header>
                     <HeaderTitle variant="h6">
                        ChatGPT
                        <KeyboardArrowDownIcon sx={{ ml: 0.5, fontSize: '1.2rem', color: '#aaa' }} />
                    </HeaderTitle>
                    <TemporaryChip
                        label="Temporary"
                        icon={<SyncProblemIcon />}
                        size="small"
                    />
                </Header>

                {/* Chat History / Initial Prompt */}
                <ChatHistory ref={chatHistoryRef}>
                    {messages.length === 0 && !loading && (
                        <InitialPromptContainer>
                             <Typography variant="h4" sx={{ color: '#e0e0e0', fontWeight: 500, mb: 3, textShadow: '0 1px 2px rgba(0,0,0,0.7)' }}> {/* Slightly stronger shadow maybe */}
                                 What can I help with?
                             </Typography>
                             <Box sx={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center', maxWidth: '600px' }}>
                                 <SuggestionChip icon={<AddIcon />} label="Load Example" onClick={() => {/* Can implement later */}}/>
                                 <SuggestionChip icon={<SearchIcon />} label="Search Web (N/A)" disabled/>
                                 <SuggestionChip icon={<LightbulbOutlinedIcon />} label="Reason (N/A)" disabled/>
                                 <SuggestionChip icon={<MoreHorizIcon />} label="More (N/A)" disabled/>
                             </Box>
                        </InitialPromptContainer>
                    )}

                    {messages.map((msg, index) => (
                        <Fade in={true} key={index}>
                            <Box sx={{display: 'flex', justifyContent: 'center', width: '100%'}}>
                                <Box sx={{maxWidth: '800px', width: '100%'}}>
                                    {msg.type === 'error' ? (
                                         <Alert
                                             severity="error"
                                             sx={{
                                                 mt: 1, mb: 2.5,
                                                 backgroundColor: 'rgba(183, 28, 28, 0.3)', // Transparent red
                                                 color: '#ffcdd2', // Light red text
                                                 border: '1px solid rgba(239, 83, 80, 0.4)', // Faint red border
                                                 maxWidth: '800px', margin: 'auto',
                                             }}
                                         >
                                            {msg.content}
                                        </Alert>
                                    ) : (
                                        <MessageBox type={msg.type}>
                                            {msg.type === 'ai' ? (
                                                <ReactMarkdown children={msg.content} />
                                            ) : (
                                                msg.content
                                            )}
                                        </MessageBox>
                                    )}
                                </Box>
                             </Box>
                        </Fade>
                    ))}
                    {loading && (
                         <Box sx={{ display: 'flex', justifyContent: 'center', p: 2 }}>
                             <CircularProgress size={24} sx={{ color: '#aaa' }} />
                         </Box>
                     )}
                </ChatHistory>

                {/* Input Area */}
                <InputAreaContainer>
                    <InputWrapper>
                        <StyledTextField
                            value={jsonInput}
                            onChange={handleJsonChange}
                            onKeyPress={handleKeyPress}
                            multiline
                            placeholder="Ask anything (paste JSON here and press Enter)"
                            variant="outlined"
                            disabled={loading}
                            spellCheck="false"
                            aria-label="JSON Input for Loan Application"
                        />
                        <SendButtonWrapper>
                            <StyledIconButton
                                onClick={handleSubmit}
                                disabled={loading || !jsonInput.trim()}
                                aria-label="Send JSON data"
                            >
                                <SendIcon fontSize="small" />
                            </StyledIconButton>
                        </SendButtonWrapper>
                    </InputWrapper>
                </InputAreaContainer>
             </ContentOverlay> {/* End Content Overlay */}

        </ChatContainer>
    );
};

export default LoanApplication;