// src/App.js

import React, { useEffect } from 'react'; 
import { Route, Routes } from 'react-router-dom';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { useAuth } from './contexts/AuthContext';
import CssBaseline from '@mui/material/CssBaseline';
import Header from './components/Header';
import Home from './components/Home';
// Car marketplace components (commented out)
// import CarListings from './components/CarListings';
// import CarDetail from './components/CarDetail';
// import SellMyCar from './components/SellMyCar';
// import SignUp from './components/SignUp';
// import Login from './components/Login';
// import PrivateRoute from './components/PrivateRoute';
// import PasswordReset from './components/PasswordReset';
// import ProfilePage from './components/ProfilePage';
// import Profile from './components/Profile';
// import EditListing from './components/EditListing';
// import RequestCar from './components/RequestCars';
// import BuyerRequests from './components/BuyerRequests';
import './App.css';
// import SavedCars from './components/SavedCars';
// import SellerProfile from './components/SellerProfile';
// import ChatInterface from './components/ChatInterface';
// import VerifyEmail from './components/VerifyEmail';
// import ConfirmPasswordReset from './components/ConfirmPasswordReset';
// import CarDescription from './components/CarDescription';
// import NewCars from './components/NewCars';
// import CompareCars from './components/CompareCars';
import GoogleCallback from './components/GoogleCallback';
// import CarParts from './components/CarParts';

// AI-focused components (keep these)
import PriceEstimator from './components/PriceEstimator';
import BankStatementAnalysis from './components/BankStatementAnalysis';
import LLMInterface from './components/LLMInterface';
import PartneringCarousel from './components/PartneringCarousel';
import LoanApplication from './components/LoanApplication';

const theme = createTheme({
  palette: {
    primary: {
      main: '#0052FF',
    },
  },
  background: {
    default: '#F1EFE5',
  },
  typography: {
    fontFamily: '"Rubik", "Noto Sans Mongolian", sans-serif',
    h1: {
      fontFamily: '"Rubik", "Noto Sans Mongolian", sans-serif',
    },
    h2: {
      fontFamily: '"Rubik", "Noto Sans Mongolian", sans-serif',
    },
    h3: {
      fontFamily: '"Rubik", "Noto Sans Mongolian", sans-serif',
    },
    h4: {
      fontFamily: '"Rubik", "Noto Sans Mongolian", sans-serif',
    },
    h5: {
      fontFamily: '"Rubik", "Noto Sans Mongolian", sans-serif',
    },
    h6: {
      fontFamily: '"Rubik", "Noto Sans Mongolian", sans-serif',
    },
    body1: {
      fontFamily: '"Rubik", "Noto Sans Mongolian", sans-serif',
    },
    body2: {
      fontFamily: '"Rubik", "Noto Sans Mongolian", sans-serif',
    },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: `
        body {
          font-family: "Rubik", "Noto Sans Mongolian", sans-serif;
        }
      `,
    },
    MuiButton: {
      styleOverrides: {
        root: {
          fontFamily: '"Rubik", "Noto Sans Mongolian", sans-serif',
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          fontFamily: '"Rubik", "Noto Sans Mongolian", sans-serif',
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        select: {
          fontFamily: '"Rubik", "Noto Sans Mongolian", sans-serif',
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          fontFamily: '"Rubik", "Noto Sans Mongolian", sans-serif',
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          fontFamily: '"Rubik", "Noto Sans Mongolian", sans-serif',
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          fontFamily: '"Rubik", "Noto Sans Mongolian", sans-serif',
        },
      },
    },
  },
});

function App() {
  const { setToken } = useAuth();  // Get setToken from AuthContext

  useEffect(() => {
    // Check for token in URL params when app loads
    const params = new URLSearchParams(window.location.search);
    const token = params.get('token');
    
    if (token) {
      // Store the token
      localStorage.setItem('token', token);
      // Remove token from URL
      window.history.replaceState({}, document.title, window.location.pathname);
      // Update auth context
      setToken(token);
    }
  }, [setToken]);  // Add setToken as dependency

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <div className="App">
        <Header />
        <Routes>
          {/* Home route */}
          <Route path="/" element={<Home />} />
          
          {/* AI-focused routes (keep these) */}
          <Route path="/price-estimator" element={<PriceEstimator />} />
          <Route path="/bank-analysis" element={<BankStatementAnalysis />} />
          <Route path="/LawGPT" element={<LLMInterface />} />
          <Route path="/partnering" element={<PartneringCarousel />} />

          <Route path="/loan-application" element={<LoanApplication />} />
          
          {/* Auth callback route */}
          <Route path="/auth/google/callback" element={<GoogleCallback />} />
          
          {/* Car marketplace routes (commented out) */}
          {/* <Route path="/saved" element={<PrivateRoute><SavedCars /></PrivateRoute>} /> */}
          {/* <Route path="/listings/:pageNum?" element={<CarListings />} /> */}
          {/* <Route path="/car/:id" element={<CarDetail />} /> */}
          {/* <Route path="/signup" element={<SignUp />} /> */}
          {/* <Route path="/login" element={<Login />} /> */}
          {/* <Route path="/seller/:sellerId" element={<SellerProfile />} /> */}
          {/* <Route path="/verify-email/:token" element={<VerifyEmail />} /> */}
          {/* <Route path="/new-car/:id" element={<CarDescription />} /> */}
          {/* <Route path="/new-cars" element={<NewCars />} /> */}
          {/* <Route path="/compare-cars" element={<CompareCars />} /> */}
          {/* <Route path="/request-car" element={<PrivateRoute><RequestCar /></PrivateRoute>} /> */}
          {/* <Route path="/buyer-requests" element={<BuyerRequests />} /> */}
          {/* <Route path="/account" element={<ProfilePage />} /> */}
          {/* <Route path="/reset-password" element={<PasswordReset />} /> */}
          {/* <Route path="/reset-password/:token" element={<ConfirmPasswordReset />} /> */}
          {/* <Route path="/edit-listing/:id" element={<PrivateRoute><EditListing /></PrivateRoute>} /> */}
          {/* <Route path="/sell-my-car" element={<PrivateRoute><SellMyCar /></PrivateRoute>} /> */}
          {/* <Route path="/profile" element={<PrivateRoute><Profile /></PrivateRoute>} /> */}
          {/* <Route path="/chat" element={<PrivateRoute><ChatInterface /></PrivateRoute>} /> */}
          {/* <Route path="/parts/:pageNum?" element={<CarParts />} /> */}
          {/* <Route path="/analytics" element={<AnalyticsDashboard />} /> */}
        </Routes>
      </div>
    </ThemeProvider>
  );
}

export default App;
