/* src/components/PartneringCarousel.js */

import React, { useState, useEffect } from "react";
import "../styles/PartneringCarousel.css";
import image1 from "../assets/images/1.png";
import image2 from "../assets/images/2.png";
import image3 from "../assets/images/3.png";

const partners = [
  {
    name: "Catapulting your business",
    description: "AI solutions tailor-made for your enterprise: saves you billions and adds even more",
    image: image1,
  },
  {
    name: "Corporate AI Assessment",
    description: "Comprehensive report that tells you how your company could incorporate AI solutions at any scale",
    image: image2,
  },
  {
    name: "Vertical AI Agents",
    description: "AI Agents to safe-guard you from human mistakes as if they're innies with fool-proof severance. Except they're working 24/7 for you.",
    image: image3,
  },
];

const PartneringCarousel = () => {
  const [activeIndex, setActiveIndex] = useState(0);
  
  useEffect(() => {
    console.log('PartneringCarousel mounted');
  }, []);

  return (
    <div className="partnering-container">
      <div className="options">
        {partners.map((partner, index) => (
          <div
            key={index}
            className={`option ${index === activeIndex ? "active" : ""}`}
            style={{ backgroundImage: `url(${partner.image})` }}
            onMouseEnter={() => setActiveIndex(index)}
          >
            <div className="shadow"></div>
            <div className="label">
              <div className="info">
                <div className="main">{partner.name}</div>
                <div className="sub">{partner.description}</div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default PartneringCarousel;
