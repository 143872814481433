import React, { useCallback } from 'react';
import Particles from "react-tsparticles";
import { loadFull } from "tsparticles";

const ParticleBackground = () => {
  const particlesInit = useCallback(async (engine) => {
    await loadFull(engine);
  }, []);

  return (
    <Particles
      id="tsparticles"
      init={particlesInit}
      options={{
        background: {
          color: "#0b0b09"
        },
        particles: {
          number: {
            value: 50,
            density: {
              enable: true,
              value_area: 800
            }
          },
          color: {
            value: ["#989989", "#989989", "#989989"]
          },
          links: {
            enable: true,
            color: "#585858",
            distance: 150,
            opacity: 0.5,
            width: 1
          },
          move: {
            enable: true,
            speed: 2,
            direction: "none",
            random: false,
            straight: false,
            outModes: {
              default: "bounce"
            }
          },
          size: {
            value: { min: 1, max: 3 }
          },
          opacity: {
            value: { min: 0.3, max: 0.7 }
          }
        }
      }}
    />
  );
};

export default ParticleBackground; 