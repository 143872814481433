// src/components/BankStatementAnalysis.js
"use client";

import React, { useState, useEffect, useRef } from "react";
import {
  Box,
  Typography,
  Alert,
  Tab,
  Tabs,
  createTheme,
  ThemeProvider,
  styled,
  Button,
  Stack,
  Tooltip,
  Fade,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Chip,
  IconButton,
  Divider,
  Modal,
  Backdrop,
  Paper,
  Grid,
} from "@mui/material";
import { api } from "../contexts/AuthContext";
import { Line, Scatter, Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  Title,
  Tooltip as ChartTooltip,
  Legend,
  TimeScale,
} from "chart.js";
import "chartjs-adapter-date-fns";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import InfoIcon from "@mui/icons-material/Info";
import InsightsIcon from "@mui/icons-material/Insights";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ErrorIcon from "@mui/icons-material/Error";
import CloseIcon from "@mui/icons-material/Close";
import MinimizeIcon from "@mui/icons-material/Minimize";
import PaginatedTable from "./PaginatedTable";
import Thinking from "./Thinking";
import "@fontsource/rubik";
import PropTypes from "prop-types";
import axios from "axios";
import FloatingBubbleBackground from "./FloatingBubbleBackground";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  Title,
  ChartTooltip,
  Legend,
  TimeScale
);

// Styled Container for dark theme with Rubik font
const Container = styled("div")(({ theme }) => ({
  padding: theme.spacing(4),
  paddingTop: theme.spacing(20), // <-- Added: extra top padding to lower page contents below the Header
  backgroundColor: "transparent",
  color: "#ffffff",
  minHeight: "100vh",
  fontFamily: "Rubik, sans-serif",
  position: "relative",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
}));

// Styled SubTitle for dark theme
const SubTitle = styled(Typography)(({ theme }) => ({
  marginTop: theme.spacing(4),
  marginBottom: theme.spacing(2),
  fontWeight: 600,
}));

// Light Theme Configuration
const darkTheme = createTheme({
  palette: {
    mode: "dark",
    primary: {
      main: "#989989", // Updated to the specified muted greenish-sandy titanium shade
    },
    secondary: {
      main: "#dc004e",
    },
    background: {
      default: "#0b0b09", // Updated to the specified dark background
      paper: "#0b0b09", // Matching background for paper elements
    },
    text: {
      primary: "#ffffff", // Changed to white for better readability on dark background
    },
  },
  typography: {
    fontFamily: "Rubik, sans-serif",
  },
});

// Accessibility props for tabs
function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

// Styled component for the "Thinking" text animation (if needed)
const ThinkingText = styled("div")(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  height: "80px",
  fontSize: "1.2rem",
  fontWeight: "normal",
  "& span": {
    display: "inline-block",
    animation: "wave 1.5s infinite",
    animationFillMode: "forwards",
  },
  "@keyframes wave": {
    "0%, 100%": { fontWeight: "normal" },
    "50%": { fontWeight: "bold" },
  },
}));

// Move this outside component as it's just a styled component
const CounterAnimation = styled('span')({
  display: 'inline-block',
  transition: 'transform 0.3s ease-out, opacity 0.3s ease-out',
  '@keyframes slideIn': {
    '0%': {
      transform: 'translateY(100%)',
      opacity: 0,
    },
    '100%': {
      transform: 'translateY(0)',
      opacity: 1,
    },
  },
  '@keyframes slideOut': {
    '0%': {
      transform: 'translateY(0)',
      opacity: 1,
    },
    '100%': {
      transform: 'translateY(-100%)',
      opacity: 0,
    },
  },
});

// Move the AnimatedPureIncome component definition outside
const AnimatedPureIncome = ({ amount, formatNumber }) => {
  const [prevDisplayAmount, setPrevDisplayAmount] = useState(amount);
  const [isAnimating, setIsAnimating] = useState(false);

  useEffect(() => {
    if (amount !== prevDisplayAmount) {
      setIsAnimating(true);
      const timer = setTimeout(() => {
        setPrevDisplayAmount(amount);
        setIsAnimating(false);
      }, 300);
      return () => clearTimeout(timer);
    }
  }, [amount, prevDisplayAmount]);

  return (
    <Box sx={{ position: 'relative', overflow: 'hidden', display: 'inline-block' }}>
      <CounterAnimation
        sx={{
          animation: isAnimating
            ? amount > prevDisplayAmount
              ? 'slideIn 0.3s ease-out'
              : 'slideOut 0.3s ease-out'
            : 'none',
        }}
      >
        {formatNumber(amount)}₮
      </CounterAnimation>
    </Box>
  );
};

// Add PropTypes
AnimatedPureIncome.propTypes = {
  amount: PropTypes.number.isRequired,
  formatNumber: PropTypes.func.isRequired,
};

// Update the ModalStyle to include the glow effect
const ModalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "80vw",
  height: "80vh",
  bgcolor: "#0b0b09",
  boxShadow: "0 0 20px #989989, 0 0 30px rgba(152, 152, 137, 0.5)", // Added glow effect
  p: 4,
  borderRadius: 2,
  overflowY: "auto",
  color: "#ffffff",
};

// Update the TransactionItem styled component
const TransactionItem = styled(Paper)(({ theme, selected }) => ({
  padding: theme.spacing(2),
  marginBottom: theme.spacing(1),
  borderRadius: "16px",
  border: selected ? "2px solid #989989" : "1px solid #e0e0e0",
  transition: "all 0.3s ease",
  textAlign: "center",
  cursor: "pointer",
  backgroundColor: "#0b0b09",
  color: "#ffffff",
  boxShadow: selected ? "0 0 20px #989989, 0 0 30px rgba(152, 152, 137, 0.5)" : "none",
  "&:hover": {
    boxShadow: selected ? "0 0 20px #989989, 0 0 30px rgba(152, 152, 137, 0.5)" : "0 2px 4px rgba(0,0,0,0.1)",
  },
}));

// Create a new styled button specifically for the Analyze button
const AnalyzeButton = styled(Button)(({ isactive }) => ({
  backgroundColor: "#0b0b09",
  color: "#989989",
  border: "2px solid #989989",
  borderRadius: "8px",
  width: "250px",
  textTransform: "none",
  padding: "12px 24px",
  fontSize: "1.2rem",
  transition: "all 0.3s ease",
  // Only apply glow effect when files are uploaded (isactive is true)
  ...(isactive === 'true' && {
    boxShadow: "0 0 20px #989989, 0 0 30px rgba(152, 152, 137, 0.5)",
    "&:hover": {
      backgroundColor: "#0b0b09",
      boxShadow: "0 0 30px #989989, 0 0 40px rgba(152, 152, 137, 0.6)",
    },
  }),
  // Keep original button styles when inactive
  ...(isactive === 'false' && {
    opacity: 0.7,
    "&:hover": {
      backgroundColor: "#0b0b09",
    },
  }),
}));

// Restore the GlowingButton for Цэвэр Орлого Тооцох
const GlowingButton = styled(Button)({
  backgroundColor: "#0b0b09",
  color: "#989989",
  border: "2px solid #989989",
  borderRadius: "8px",
  padding: "12px 24px",
  fontWeight: "bold",
  boxShadow: "0 0 20px #989989, 0 0 30px rgba(152, 152, 137, 0.5)",
  transition: "all 0.3s ease",
  width: "250px",
  textTransform: "none",
  fontSize: "1.2rem",
  "&:hover": {
    backgroundColor: "#0b0b09",
    boxShadow: "0 0 30px #989989, 0 0 40px rgba(152, 152, 137, 0.6)",
  },
});

// Update the MinimizedModal styled component
const MinimizedModal = styled(Box)(({ theme }) => ({
  position: "fixed",
  bottom: theme.spacing(2),
  right: theme.spacing(2),
  backgroundColor: "#0b0b09",
  color: "#989989",
  padding: theme.spacing(1, 2),
  borderRadius: "25px",
  display: "flex",
  alignItems: "center",
  cursor: "pointer",
  zIndex: 1200,
  border: "2px solid #989989",
  boxShadow: "0 0 20px #989989, 0 0 30px rgba(152, 152, 137, 0.5)",
  transition: "all 0.3s ease",
  fontWeight: "bold",
  "&:hover": {
    boxShadow: "0 0 30px #989989, 0 0 40px rgba(152, 152, 137, 0.6)",
  },
}));

// Update the styled title component for transaction sections
const SectionTitle = styled(Box)({
  backgroundColor: "#0b0b09",
  border: "2px solid #989989",
  borderRadius: "16px",
  padding: "10px 20px",
  marginBottom: "20px",
  display: "inline-block",
  "& .MuiTypography-root": {
    color: "#989989",
    fontWeight: "bold",
    fontSize: "1.1rem",
  }
});

// Update the income boxes styling
const IncomeBox = styled(Box)({
  backgroundColor: "#0b0b09",
  border: "2px solid #989989",
  padding: "16px",
  borderRadius: "16px",
  minWidth: 200,
  textAlign: "center",
  boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
  "& .MuiTypography-root": {
    color: "#989989",
    fontWeight: "bold",
  }
});

// Explanation text in Mongolian
const repeatedAccountsTooltip = `The Core Idea:
Transfers to/from the owner's own account do not represent real, external income or expenses.
If the same owner sends (credit) money to themselves at another bank account and later receives (debit) some back, those flows can inflate the "Total Income" if we blindly sum all credits.
Hence, to find "true" or "pure" income, you want to remove or offset these "internal" transfers.`;

const suspiciousTooltip = `Цэвэр орлогод тооцохгүй гэж үзсэн сэжигтэй орлогын гүйлгээнүүд.
Та цэвэр орлого мөн гэж дүгнэвэл дээр нь дарснаар идэвхгүй болгоно.`;

// Utility to compute monthly income based on actual date range
const calculateMonthlyIncome = (pureIncome, rawTransactions) => {
  if (!rawTransactions || rawTransactions.length === 0) return 0;
  if (pureIncome <= 0) return 0;

  let earliestDate = null;
  let latestDate = null;

  for (const txn of rawTransactions) {
    const d = new Date(txn.transaction_date);
    if (!earliestDate || d < earliestDate) earliestDate = d;
    if (!latestDate || d > latestDate) latestDate = d;
  }

  if (!earliestDate || !latestDate) {
    return 0;
  }

  const msInDay = 24 * 60 * 60 * 1000;
  const diffDays = (latestDate - earliestDate) / msInDay;
  const approxMonths = diffDays / 30;
  if (approxMonths <= 0) return 0;
  const monthly = pureIncome / approxMonths;
  return Math.round(monthly);
};

const BankStatementAnalysis = () => {
  // -- states --
  const [files, setFiles] = useState([]);
  const [analysis, setAnalysis] = useState(null);
  const [fileResults, setFileResults] = useState([]);
  const [loadingAnalyze, setLoadingAnalyze] = useState(false);
  const [loadingPredict, setLoadingPredict] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);
  const [tabValue, setTabValue] = useState(0);
  const [showPrediction, setShowPrediction] = useState(false);
  const [openInDropdowns, setOpenInDropdowns] = useState({});
  const [openOutDropdowns, setOpenOutDropdowns] = useState({});

  const [predictionForm, setPredictionForm] = useState({
    totalIncome: 0,
    suspiciousTransactions: [],
    repeatedAccounts: [],
  });
  const [showPredictionForm, setShowPredictionForm] = useState(false);
  const [isMinimized, setIsMinimized] = useState(false);
  const modalRef = useRef(null);

  const toggleInDropdownForAccount = (accNum) => {
    setOpenInDropdowns((prev) => ({
      ...prev,
      [accNum]: !prev[accNum],
    }));
  };

  const toggleOutDropdownForAccount = (accNum) => {
    setOpenOutDropdowns((prev) => ({
      ...prev,
      [accNum]: !prev[accNum],
    }));
  };

  const handleCloseModal = () => {
    setShowPredictionForm(false);
    setIsMinimized(false);
  };

  const handleMinimizeModal = () => {
    setIsMinimized(true);
  };

  const handleMaximizeModal = () => {
    setIsMinimized(false);
  };

  const handleFileChange = (e) => {
    const selectedFiles = Array.from(e.target.files);
    const validFiles = selectedFiles.filter(
      (file) => file.type === "application/pdf"
    );
    if (validFiles.length !== selectedFiles.length) {
      setError("Only PDF files are allowed.");
      return;
    }
    setFiles(validFiles);
    setError(null);
  };

  const handleAnalyze = async () => {
    try {
      await axios.post("/api/analytics/", {
        event_type: "button_click",
        path: "BankStatementAnalysis:AnalyzePDF",
        ip_address: null,
      });
    } catch (err) {
      console.error("Error logging analyze button click:", err);
    }

    if (files.length === 0) {
      setError("Please upload at least one PDF file.");
      return;
    }

    setLoadingAnalyze(true);
    setError(null);
    setAnalysis(null);
    setSuccess(false);
    setShowPrediction(false);
    setFileResults([]);

    const formData = new FormData();
    files.forEach((file) => {
      formData.append("pdf_file", file);
    });

    try {
      const response = await api.post("/bank-statements/", formData, {
        headers: { "Content-Type": "multipart/form-data" },
      });

      setAnalysis(response.data.analysis_data);
      setFileResults(response.data.file_results || []);
      setSuccess(true);
      setFiles([]);
    } catch (err) {
      if (err.response && err.response.data) {
        if (err.response.data.error) {
          setError(err.response.data.error);
          setFileResults(err.response.data.file_results || []);
        } else {
          setError("An unexpected error occurred during upload.");
        }
      } else {
        setError("Unable to connect to the server. Please try again later.");
      }
    } finally {
      setLoadingAnalyze(false);
    }
  };

  const handlePredict = async () => {
    try {
      await axios.post("/api/analytics/", {
        event_type: "button_click",
        path: "BankStatementAnalysis:PredictWithGPT",
        ip_address: null,
      });
    } catch (err) {
      console.error("Error logging GPT prediction click:", err);
    }

    const totalIncome = analysis.summary[0]?.["Нийт Орлого"] || 0;

    const suspiciousTransactions = (analysis.filtered_data || [])
      .filter((trx) => parseFloat(trx.credit_transaction) > 0)
      .map((trx) => ({
        ...trx,
        checked: true,
      }));

    const repeatedAccounts = Object.entries(analysis.transaction_analysis || [])
      .filter(([accountNumber, value]) => {
        return accountNumber;
      })
      .map(([accountNumber, value]) => {
        const df = value[0] || [];
        const totalIn = df.reduce((acc, row) => acc + (row.credit_sum || 0), 0);
        const totalOut = df.reduce((acc, row) => acc + (row.debit_sum || 0), 0);

        let wordCountsIn = {};
        let wordCountsOut = {};
        df.forEach((monthRow) => {
          if (Array.isArray(monthRow.top_words_credit)) {
            monthRow.top_words_credit.forEach((word) => {
              wordCountsIn[word] = (wordCountsIn[word] || 0) + 1;
            });
          }
          if (Array.isArray(monthRow.top_words_debit)) {
            monthRow.top_words_debit.forEach((word) => {
              wordCountsOut[word] = (wordCountsOut[word] || 0) + 1;
            });
          }
        });

        const topWordsIn = Object.entries(wordCountsIn)
          .map(([word, count]) => ({ word, count }))
          .sort((a, b) => b.count - a.count);
        const topWordsOut = Object.entries(wordCountsOut)
          .map(([word, count]) => ({ word, count }))
          .sort((a, b) => b.count - a.count);

        return {
          accountNumber,
          totalIn,
          totalOut,
          topWordsIn,
          topWordsOut,
          checked: true,
        };
      })
      .filter((accObj) => (accObj.totalIn - accObj.totalOut) > 0)
      .sort((a, b) => b.totalIn - b.totalOut - (a.totalIn - a.totalOut));

    setPredictionForm({
      totalIncome,
      suspiciousTransactions,
      repeatedAccounts,
    });
    setShowPredictionForm(true);
  };

  const handleTransactionClick = (type, index) => {
    setPredictionForm((prev) => {
      const key = type === "suspicious" ? "suspiciousTransactions" : "repeatedTransactions";
      const updatedTransactions = [...prev[key]];
      updatedTransactions[index] = {
        ...updatedTransactions[index],
        checked: !updatedTransactions[index].checked
      };

      return {
        ...prev,
        [key]: updatedTransactions
      };
    });
  };

  const handleRepeatedAccountClick = (index) => {
    setPredictionForm((prev) => {
      const updated = [...prev.repeatedAccounts];
      updated[index] = {
        ...updated[index],
        checked: !updated[index].checked,
      };
      return { ...prev, repeatedAccounts: updated };
    });
  };

  const calculatePureIncome = () => {
    const { totalIncome, suspiciousTransactions, repeatedAccounts } = predictionForm;
    let result = totalIncome;

    const suspiciousSum = suspiciousTransactions.reduce((sum, trx) => {
      return trx.checked
        ? sum + parseFloat(trx.credit_transaction || 0)
        : sum;
    }, 0);

    result -= suspiciousSum;

    const repeatedSum = repeatedAccounts.reduce((sum, accObj) => {
      const net = accObj.totalIn - accObj.totalOut;
      return accObj.checked
        ? sum + net
        : sum;
    }, 0);

    return Math.round(result);
  };

  const handleChangeTab = (event, newValue) => {
    setTabValue(newValue);
  };

  const formatNumber = (num) => {
    if (num == null || isNaN(num)) return num;
    return Math.round(num).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  let monthlyStats = [];
  let summaryData = [];
  let creditData = [];
  let debitData = [];
  let analysisResults = [];
  let transactionAnalysis = {};
  let rawTransactions = [];
  let nightTransactions = [];
  let filteredData = [];
  let predictedMonthlyIncome = 0;
  let regression = {};

  if (analysis) {
    monthlyStats = analysis.monthly_stats || [];
    summaryData = analysis.summary || [];
    creditData = analysis.credit_transactions || [];
    debitData = analysis.debit_transactions || [];
    analysisResults = analysis.analysis || [];
    transactionAnalysis = analysis.transaction_analysis || {};
    rawTransactions = analysis.raw_transactions || [];
    nightTransactions = analysis.night_transactions || [];
    filteredData = analysis.filtered_data || [];
    predictedMonthlyIncome = analysis.predicted_monthly_income || 0;
    regression = analysis.income_expense_regression || {};
  }

  const monthlyStatsColumns =
    monthlyStats.length > 0 ? Object.keys(monthlyStats[0]) : [];
  const summaryDataColumns =
    summaryData.length > 0 ? Object.keys(summaryData[0]) : [];
  const analysisResultsColumns =
    analysisResults.length > 0 ? Object.keys(analysisResults[0]) : [];
  const rawTransactionsColumns =
    rawTransactions.length > 0 ? Object.keys(rawTransactions[0]) : [];
  const nightTransactionsColumns =
    nightTransactions.length > 0 ? Object.keys(nightTransactions[0]) : [];
  const filteredDataColumns =
    filteredData.length > 0 ? Object.keys(filteredData[0]) : [];

  const analysisResultsData = analysisResults.map((item, index) => ({
    "#": index + 1,
    ...item,
  }));

  const months = monthlyStats.map((item) => {
    const ym = item["Сар"];
    return `${ym}-01`;
  });

  const income = monthlyStats.map((item) => item["Нийт Орлого"]);
  const expenses = monthlyStats.map((item) => item["Нийт Зарлага"]);

  const incomeDataset = {
    label: "Орлого",
    data: income,
    borderColor: "rgba(75, 192, 192, 1)",
    backgroundColor: "rgba(75, 192, 192, 0.2)",
    fill: true,
  };

  const expenseDataset = {
    label: "Зарлага",
    data: expenses,
    borderColor: "rgba(255, 99, 132, 1)",
    backgroundColor: "rgba(255, 99, 132, 0.2)",
    fill: true,
  };

  let predictedIncomeDataset = null;
  const predictedIncome = regression.predicted_income || [];
  if (predictedIncome.length === months.length) {
    predictedIncomeDataset = {
      label: "Таамагласан Орлого (LR)",
      data: predictedIncome,
      borderColor: "rgba(255, 159, 64, 1)",
      backgroundColor: "rgba(255, 159, 64, 0.2)",
      fill: false,
      borderDash: [5, 5],
      pointRadius: 3,
      borderWidth: 2,
      order: 3,
    };
  }

  const incomeExpenseData = {
    labels: months,
    datasets: [incomeDataset, expenseDataset],
  };

  if (predictedIncomeDataset) {
    incomeExpenseData.datasets.push(predictedIncomeDataset);
  }

  const incomeExpenseOptions = {
    scales: {
      x: {
        type: "time",
        time: {
          unit: "month",
        },
        title: { 
          display: true, 
          text: "Огноо",
          color: "#ffffff"
        },
        ticks: {
          color: "#ffffff"
        },
        grid: {
          color: "rgba(255, 255, 255, 0.1)"
        }
      },
      y: {
        title: { 
          display: true, 
          text: "Хэмжээ",
          color: "#ffffff"
        },
        ticks: {
          color: "#ffffff"
        },
        grid: {
          color: "rgba(255, 255, 255, 0.1)"
        }
      },
    },
    responsive: true,
    plugins: {
      legend: { 
        position: "top",
        labels: {
          color: "#ffffff"
        }
      },
      title: {
        display: true,
        text: "Сарын Орлого, Зарлага + Single LR Trend",
        color: "#ffffff"
      },
    },
  };

  const creditScatterData = {
    datasets: [
      {
        label: "Орлого",
        data: creditData.map((d) => ({
          x: d.transaction_date,
          y: d.credit_transaction,
          description: d.description || "",
        })),
        backgroundColor: "rgba(75, 192, 192, 1)",
      },
    ],
  };
  const creditScatterOptions = {
    responsive: true,
    scales: {
      x: {
        type: "time",
        time: { unit: "day" },
        title: { 
          display: true, 
          text: "Огноо",
          color: "#ffffff"
        },
        ticks: {
          color: "#ffffff"
        },
        grid: {
          color: "rgba(255, 255, 255, 0.1)"
        }
      },
      y: { 
        title: { 
          display: true, 
          text: "Үнийн Дүн",
          color: "#ffffff"
        },
        ticks: {
          color: "#ffffff"
        },
        grid: {
          color: "rgba(255, 255, 255, 0.1)"
        }
      },
    },
    plugins: {
      tooltip: {
        displayColors: false,
        callbacks: {
          label: (context) => {
            const { description } = context.raw || {};
            return description;
          },
        },
        backgroundColor: "rgba(0, 0, 0, 0.8)",
        titleColor: "#ffffff",
        bodyColor: "#ffffff"
      },
      title: { 
        display: true, 
        text: "Орлогын Хэмжээ",
        color: "#ffffff"
      },
      legend: {
        labels: {
          color: "#ffffff"
        }
      }
    },
  };

  const debitScatterData = {
    datasets: [
      {
        label: "Зарлага",
        data: debitData.map((d) => ({
          x: d.transaction_date,
          y: d.debit_transaction,
          description: d.description || "",
        })),
        backgroundColor: "rgba(255, 99, 132, 1)",
      },
    ],
  };
  const debitScatterOptions = {
    responsive: true,
    scales: {
      x: {
        type: "time",
        time: { unit: "day" },
        title: { 
          display: true, 
          text: "Огноо",
          color: "#ffffff"
        },
        ticks: {
          color: "#ffffff"
        },
        grid: {
          color: "rgba(255, 255, 255, 0.1)"
        }
      },
      y: { 
        title: { 
          display: true, 
          text: "Үнийн Дүн",
          color: "#ffffff"
        },
        ticks: {
          color: "#ffffff"
        },
        grid: {
          color: "rgba(255, 255, 255, 0.1)"
        }
      },
    },
    plugins: {
      tooltip: {
        displayColors: false,
        callbacks: {
          label: (context) => {
            const { description } = context.raw || {};
            return description;
          },
        },
        backgroundColor: "rgba(0, 0, 0, 0.8)",
        titleColor: "#ffffff",
        bodyColor: "#ffffff"
      },
      title: { 
        display: true, 
        text: "Зарлагын Хэмжээ",
        color: "#ffffff"
      },
      legend: {
        labels: {
          color: "#ffffff"
        }
      }
    },
  };

  const getDayBin = (day) => {
    if (day >= 1 && day <= 5) return "Days 1-5";
    if (day >= 6 && day <= 10) return "Days 6-10";
    if (day >= 11 && day <= 15) return "Days 11-15";
    if (day >= 16 && day <= 20) return "Days 16-20";
    if (day >= 21 && day <= 25) return "Days 21-25";
    return "Days 26-31";
  };

  const calculateFiveDayBinData = (transactions) => {
    const binMap = {};

    transactions.forEach((trx) => {
      if (!trx.transaction_date || !trx.credit_transaction) return;
      const dateObj = new Date(trx.transaction_date);
      if (isNaN(dateObj.getTime())) return;

      const dayOfMonth = dateObj.getDate();
      const binLabel = getDayBin(dayOfMonth);
      const creditAmt = parseFloat(trx.credit_transaction) || 0;
      const desc = trx.description ? trx.description.trim() : "";
      const acc = trx.transaction_account ? trx.transaction_account.trim() : "";

      if (!binMap[binLabel]) {
        binMap[binLabel] = {
          totalCredit: 0,
          repeatedDescriptions: {},
          transactionAccounts: {},
          monthsVisited: new Set(),
        };
      }

      binMap[binLabel].totalCredit += creditAmt;
      binMap[binLabel].monthsVisited.add(
        `${dateObj.getFullYear()}-${String(dateObj.getMonth() + 1).padStart(
          2,
          "0"
        )}`
      );

      if (desc) {
        if (!binMap[binLabel].repeatedDescriptions[desc]) {
          binMap[binLabel].repeatedDescriptions[desc] = 0;
        }
        binMap[binLabel].repeatedDescriptions[desc] += creditAmt;
      }
      if (acc) {
        if (!binMap[binLabel].transactionAccounts[acc]) {
          binMap[binLabel].transactionAccounts[acc] = 0;
        }
        binMap[binLabel].transactionAccounts[acc] += creditAmt;
      }
    });

    const binsOrder = [
      "Days 1-5",
      "Days 6-10",
      "Days 11-15",
      "Days 16-20",
      "Days 21-25",
      "Days 26-31",
    ];
    const labels = [];
    const data = [];
    const hoverInfo = [];

    binsOrder.forEach((b) => {
      if (binMap[b]) {
        const total = binMap[b].totalCredit;
        const monthsCount = binMap[b].monthsVisited.size || 1;
        const avg = total / monthsCount;

        labels.push(b);
        data.push(avg);

        const descEntries = Object.entries(
          binMap[b].repeatedDescriptions
        ).sort((a, b) => b[1] - a[1]);
        const accEntries = Object.entries(
          binMap[b].transactionAccounts
        ).sort((a, b) => b[1] - a[1]);

        const topDesc = descEntries.slice(0, 5).map(([desc, amt]) => ({
          desc,
          amount: amt,
        }));
        const topAcc = accEntries.slice(0, 5).map(([acc, amt]) => ({
          account: acc,
          amount: amt,
        }));

        hoverInfo.push({ descriptions: topDesc, accounts: topAcc });
      } else {
        labels.push(b);
        data.push(0);
        hoverInfo.push({ descriptions: [], accounts: [] });
      }
    });

    return { labels, data, hoverInfo };
  };

  const [fiveDayBinChartData, setFiveDayBinChartData] = useState(null);

  useEffect(() => {
    if (rawTransactions.length > 0) {
      const result = calculateFiveDayBinData(rawTransactions);
      setFiveDayBinChartData(result);
    }
  }, [rawTransactions]);

  const fiveDayBinChartOptions = {
    responsive: true,
    plugins: {
      title: {
        display: true,
        text: "Average Income in 5-Day Bins (across all months)",
        color: "#ffffff"
      },
      legend: {
        labels: {
          color: "#ffffff"
        }
      },
      tooltip: {
        backgroundColor: "rgba(0, 0, 0, 0.8)",
        titleColor: "#ffffff",
        bodyColor: "#ffffff",
        callbacks: {
          label: function (context) {
            const val = context.parsed.y;
            return `Avg Income: ${formatNumber(val.toFixed(2))}`;
          },
          footer: function (context) {
            const idx = context[0].dataIndex;
            const dataset = context[0].dataset;
            if (!dataset || !dataset.hoverInfo) return "";

            const hoverData = dataset.hoverInfo[idx];
            if (!hoverData) return "";

            const { descriptions, accounts } = hoverData;
            let footerStr = "\nTop Descriptions by Amt:\n";
            if (descriptions.length === 0) {
              footerStr += "   (none)\n";
            } else {
              descriptions.forEach((d) => {
                footerStr += `   • ${d.desc} (${formatNumber(
                  d.amount.toFixed(2)
                )})\n`;
              });
            }

            footerStr += "\nTop Accounts by Amt:\n";
            if (accounts.length === 0) {
              footerStr += "   (none)";
            } else {
              accounts.forEach((a) => {
                footerStr += `   • ${a.account} (${formatNumber(
                  a.amount.toFixed(2)
                )})\n`;
              });
            }

            return footerStr;
          },
        },
      },
    },
    scales: {
      x: {
        ticks: {
          color: "#ffffff"
        },
        grid: {
          color: "rgba(255, 255, 255, 0.1)"
        }
      },
      y: {
        title: {
          display: true,
          text: "Average Income",
          color: "#ffffff"
        },
        ticks: {
          color: "#ffffff"
        },
        grid: {
          color: "rgba(255, 255, 255, 0.1)"
        }
      },
    },
  };

  const renderThinkingText = () => {
    const text = "Thinking";
    return (
      <ThinkingText>
        {text.split("").map((char, index) => (
          <span
            key={index}
            style={{
              animationDelay: `${index * 0.3}s`,
            }}
          >
            {char}
          </span>
        ))}
      </ThinkingText>
    );
  };

  const mainWordStyle = {
    fontWeight: "bold",
    color: "#ffffff",
  };

  return (
    <ThemeProvider theme={darkTheme}>
      <div style={{ position: "relative", overflow: "hidden" }}>
        <FloatingBubbleBackground />
        <Container>
          {/* Error Alert remains if an error occurs */}
          {error && (
            <Alert severity="error" sx={{ mb: 2 }}>
              {error}
            </Alert>
          )}

              <Box
                className="button-overlay"
                sx={{
                  position: analysis ? "absolute" : "static",
                  top: analysis ? 160 : undefined, // increased vertical offset so buttons aren't hidden under the header
                  left: analysis ? 16 : undefined,
                }}
              >
                <Stack
                  direction="row" // always place buttons in a row (Analyze on the right of Choose Files)
                  spacing={2}
                  mt={analysis ? 0 : 15}
                  justifyContent="flex-start"
                  alignItems="center"
                >
              <Tooltip title="Select PDF files" arrow>
                <Button
                  variant="contained"
                  component="label"
                  startIcon={<UploadFileIcon />}
                  sx={{
                    width: "250px",
                    textTransform: "none",
                    backgroundColor: "#1976d2",
                    "&:hover": { backgroundColor: "#115293" },
                    padding: "12px 24px",
                    fontSize: "1.2rem",
                  }}
                >
                  Choose Files
                  <input
                    type="file"
                    accept="application/pdf"
                    multiple
                    hidden
                    onChange={handleFileChange}
                  />
                </Button>
              </Tooltip>

              <Tooltip title="Analyze the uploaded PDF files" arrow>
                <span>
                  <AnalyzeButton
                    variant="outlined"
                    onClick={handleAnalyze}
                    disabled={loadingAnalyze || files.length === 0}
                    startIcon={<PlayArrowIcon />}
                    isactive={(files.length > 0).toString()}
                  >
                    {loadingAnalyze ? "Analyzing..." : "Analyze"}
                  </AnalyzeButton>
                </span>
              </Tooltip>
            </Stack>
          </Box>

          {/* Analysis Results */}
          {analysis && (
            <>
              {/* Prediction Section */}
              <Box
                mb={4}
                display="flex"
                flexDirection="column"
                justifyContent="center"
                alignItems="center"
                position="relative"
                aria-live="polite"
              >
                {!showPrediction && !showPredictionForm && (
                  <Box display="flex" justifyContent="center" width="100%">
                    <Button
                      variant="contained"
                      onClick={handlePredict}
                      // Note: Consider disabling this if analysis data isn't fully ready for prediction
                    >
                      ЦЭВЭР ОРЛОГО ТООЦОХ
                    </Button>
                  </Box>
                )}

                {/* Prediction Modal */}
                <Modal
                  open={showPredictionForm && !isMinimized}
                  onClose={handleCloseModal}
                  closeAfterTransition
                  slots={{ backdrop: Backdrop }}
                  slotProps={{
                    backdrop: {
                      timeout: 500,
                    },
                  }}
                  ref={modalRef}
                >
                  <Fade in={showPredictionForm && !isMinimized}>
                    <Box sx={ModalStyle}>
                      <IconButton
                        aria-label="close"
                        onClick={handleCloseModal}
                        sx={{
                          position: "absolute",
                          right: 8,
                          top: 8,
                          color: (theme) => theme.palette.grey[500],
                        }}
                      >
                        <CloseIcon />
                      </IconButton>
                      <IconButton
                        aria-label="minimize"
                        onClick={handleMinimizeModal}
                        sx={{
                          position: "absolute",
                          right: 40,
                          top: 8,
                          color: (theme) => theme.palette.grey[500],
                        }}
                      >
                        <MinimizeIcon />
                      </IconButton>

                      <Box
                        display="flex"
                        justifyContent="center"
                        gap={4}
                        flexWrap="wrap"
                        mb={3}
                        mt={3}
                      >
                        <IncomeBox>
                          <Typography variant="body1" sx={{ mb: 1 }}>
                            Нийт Орлого
                          </Typography>
                          <Typography variant="h6">
                            {formatNumber(predictionForm.totalIncome)}₮
                          </Typography>
                        </IncomeBox>

                        <IncomeBox>
                          <Typography variant="body1" sx={{ mb: 1 }}>
                            Цэвэр Орлого
                          </Typography>
                          <Typography variant="h6">
                            <AnimatedPureIncome
                              amount={calculatePureIncome()}
                              formatNumber={formatNumber}
                            />
                          </Typography>
                        </IncomeBox>

                        <IncomeBox>
                          <Typography variant="body1" sx={{ mb: 1 }}>
                            Сарын Дундаж
                          </Typography>
                          <Typography variant="h6">
                            <AnimatedPureIncome
                              amount={calculateMonthlyIncome(calculatePureIncome(), rawTransactions)}
                              formatNumber={formatNumber}
                            />
                          </Typography>
                        </IncomeBox>
                      </Box>

                      <Box sx={{ textAlign: "center", fontFamily: "Rubik, sans-serif" }}>
                        <SectionTitle>
                          <Stack direction="row" alignItems="center" spacing={1}>
                            <Typography sx={{ fontFamily: "Rubik, sans-serif" }}>
                              Хасагдсан Сэжигтэй Орлогууд:
                            </Typography>
                            <Tooltip title={suspiciousTooltip}>
                              <IconButton size="small" sx={{ color: "#989989" }}>
                                <InfoIcon />
                              </IconButton>
                            </Tooltip>
                          </Stack>
                        </SectionTitle>
                      </Box>
                      <Grid container spacing={2}>
                        {predictionForm.suspiciousTransactions.map(
                          (trx, index) => (
                            <Grid item xs={12} sm={6} key={index}>
                              <TransactionItem
                                selected={trx.checked}
                                onClick={() =>
                                  handleTransactionClick("suspicious", index)
                                }
                              >
                                <Typography>
                                  {trx.description} -{" "}
                                  {formatNumber(trx.credit_transaction)}₮
                                </Typography>
                              </TransactionItem>
                            </Grid>
                          )
                        )}
                      </Grid>

                      <Divider sx={{ my: 2 }} />

                      <Box sx={{ textAlign: "center", fontFamily: "Rubik, sans-serif" }}>
                        <SectionTitle>
                          <Stack direction="row" alignItems="center" spacing={1}>
                            <Typography sx={{ fontFamily: "Rubik, sans-serif" }}>
                              Хасагдсан Давтагдсан Орлогууд:
                            </Typography>
                            <Tooltip title={repeatedAccountsTooltip}>
                              <IconButton size="small" sx={{ color: "#989989" }}>
                                <InfoIcon />
                              </IconButton>
                            </Tooltip>
                          </Stack>
                        </SectionTitle>
                      </Box>
                      <Grid container spacing={2}>
                        {predictionForm.repeatedAccounts.map((accObj, index) => {
                          const isInOpen = !!openInDropdowns[accObj.accountNumber];
                          const isOutOpen = !!openOutDropdowns[accObj.accountNumber];

                          return (
                            <Grid item xs={12} sm={6} md={4} key={index}>
                              <TransactionItem
                                selected={accObj.checked}
                                onClick={() => handleRepeatedAccountClick(index)}
                                sx={{ position: "relative" }}
                              >
                                <Typography
                                  variant="subtitle1"
                                  sx={{ fontWeight: "bold", mb: 1 }}
                                >
                                  {accObj.accountNumber}
                                </Typography>

                                <Box
                                  display="flex"
                                  alignItems="center"
                                  justifyContent="space-between"
                                  gap={1}
                                >
                                  <Box sx={{ position: "relative", flex: 1 }}>
                                    <Box
                                      sx={{
                                        cursor: "pointer",
                                        border: "1px solid #989989",
                                        borderRadius: "8px",
                                        p: 1,
                                      }}
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        toggleInDropdownForAccount(accObj.accountNumber);
                                      }}
                                    >
                                      <Typography variant="body2" sx={{ fontWeight: "bold" }}>
                                        Нийт Орсон
                                      </Typography>
                                      <Typography variant="body2">
                                        {formatNumber(accObj.totalIn)}₮
                                      </Typography>
                                    </Box>

                                    {isInOpen && (
                                      <Box
                                        sx={{
                                          position: "absolute",
                                          top: "100%",
                                          left: 0,
                                          right: 0,
                                          backgroundColor: "#111",
                                          borderRadius: "8px",
                                          p: 1,
                                          mt: 1,
                                          textAlign: "left",
                                          zIndex: 5,
                                        }}
                                        onClick={(e) => e.stopPropagation()}
                                      >
                                        <Typography variant="body2" sx={{ fontWeight: "bold", mb: 1 }}>
                                          Давтагдсан үгс:
                                        </Typography>
                                        {accObj.topWordsIn && accObj.topWordsIn.length > 0 ? (
                                          accObj.topWordsIn.map((tw, i) => (
                                            <Typography key={i} variant="body2" sx={{ ml: 1 }}>
                                              • {tw.word} ({tw.count})
                                            </Typography>
                                          ))
                                        ) : (
                                          <Typography variant="body2" sx={{ ml: 1 }}>
                                            No repeated words
                                          </Typography>
                                        )}
                                      </Box>
                                    )}
                                  </Box>

                                  <Box sx={{ position: "relative", flex: 1 }}>
                                    <Box
                                      sx={{
                                        cursor: "pointer",
                                        border: "1px solid #989989",
                                        borderRadius: "8px",
                                        p: 1,
                                      }}
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        toggleOutDropdownForAccount(accObj.accountNumber);
                                      }}
                                    >
                                      <Typography variant="body2" sx={{ fontWeight: "bold" }}>
                                        Нийт Гарсан
                                      </Typography>
                                      <Typography variant="body2">
                                        {formatNumber(accObj.totalOut)}₮
                                      </Typography>
                                    </Box>

                                    {isOutOpen && (
                                      <Box
                                        sx={{
                                          position: "absolute",
                                          top: "100%",
                                          left: 0,
                                          right: 0,
                                          backgroundColor: "#111",
                                          borderRadius: "8px",
                                          p: 1,
                                          mt: 1,
                                          textAlign: "left",
                                          zIndex: 5,
                                        }}
                                        onClick={(e) => e.stopPropagation()}
                                      >
                                        <Typography variant="body2" sx={{ fontWeight: "bold", mb: 1 }}>
                                          Давтагдсан үгс:
                                        </Typography>
                                        {accObj.topWordsOut && accObj.topWordsOut.length > 0 ? (
                                          accObj.topWordsOut.map((tw, i) => (
                                            <Typography key={i} variant="body2" sx={{ ml: 1 }}>
                                              • {tw.word} ({tw.count})
                                            </Typography>
                                          ))
                                        ) : (
                                          <Typography variant="body2" sx={{ ml: 1 }}>
                                            No repeated words
                                          </Typography>
                                        )}
                                      </Box>
                                    )}
                                  </Box>
                                </Box>
                              </TransactionItem>
                            </Grid>
                          );
                        })}
                      </Grid>
                    </Box>
                  </Fade>
                </Modal>

                {isMinimized && (
                  <MinimizedModal onClick={handleMaximizeModal}>
                    <Typography
                      variant="body1"
                      sx={{
                        fontWeight: "bold",
                        display: "flex",
                        alignItems: "center",
                        gap: 1,
                      }}
                    >
                      Цэвэр Орлого: {formatNumber(calculatePureIncome())}₮
                    </Typography>
                  </MinimizedModal>
                )}

                <Fade in={showPrediction} timeout={1000}>
                  <Box sx={{ position: "absolute" }}>
                    <Thinking
                      text="Thinking"
                      fontSize={24}
                      animationDuration={2}
                      colorStops={[
                        { color: "#d6d6d6", position: 0 },
                        { color: "#000000", position: 50 },
                        { color: "#808080", position: 100 },
                      ]}
                    />
                  </Box>
                </Fade>
              </Box>

              <Box sx={{ borderBottom: 1, borderColor: "divider", mb: 2 }}>
                <Tabs
                  value={tabValue}
                  onChange={handleChangeTab}
                  textColor="primary"
                  TabIndicatorProps={{
                    style: {
                      backgroundColor: "#989989",
                      height: 3
                    }
                  }}
                >
                  <Tab label="Ерөнхий Анализ" {...a11yProps(0)} />
                  <Tab label="Давтагдсан Гүйлгээ" {...a11yProps(1)} />
                  <Tab label="Нэмэлт Мэдээлэл" {...a11yProps(2)} />
                </Tabs>
              </Box>

              {tabValue === 0 && (
                <>
                  <SubTitle variant="h5">
                    Орлого Зарлага Статистик Сараар
                  </SubTitle>
                  <PaginatedTable
                    columns={monthlyStatsColumns}
                    data={monthlyStats}
                  />

                  <SubTitle variant="h5" sx={{ mt: 4 }}>
                    Нийт Лавлагаа
                  </SubTitle>
                  <PaginatedTable
                    columns={summaryDataColumns}
                    data={summaryData}
                  />

                  {/* --- Line Chart --- */}
                  {/* ADDED CHECK: Ensure data is ready before rendering */}
                  {analysis && incomeExpenseData?.labels?.length > 0 && incomeExpenseData?.datasets?.length > 0 && (
                    <Box mt={4} sx={{
                      width: "100%",
                      minHeight: 800,
                      '& canvas': {
                        width: '100% !important',
                        maxWidth: '100%'
                      }
                    }}>
                      <Line
                        data={incomeExpenseData}
                        options={{
                          ...incomeExpenseOptions,
                          maintainAspectRatio: false,
                          responsive: true,
                          devicePixelRatio: 1, // Consider removing or adjusting if performance is an issue on some devices
                        }}
                      />
                    </Box>
                  )}

                  {/* --- Scatter Charts --- */}
                  {/* ADDED CHECK: Render container only if at least one scatter chart has data */}
                  {analysis && (creditScatterData?.datasets?.[0]?.data?.length > 0 || debitScatterData?.datasets?.[0]?.data?.length > 0) && (
                    <Box mt={4} width="100%" sx={{
                      display: "flex",
                      gap: 4,
                      flexDirection: {xs: 'column', md: 'row'},
                      '& canvas': {
                        width: '100% !important',
                        maxWidth: '100%'
                      }
                    }}>
                      {/* Credit Scatter - ADDED CHECK: Ensure its specific data is ready */}
                      {creditScatterData?.datasets?.[0]?.data?.length > 0 && (
                        <Box width={{xs: '100%', md: '50%'}} sx={{ minHeight: 500 }}>
                          <Scatter
                            data={creditScatterData}
                            options={{ ...creditScatterOptions, maintainAspectRatio: false }}
                          />
                        </Box>
                      )}
                      {/* Debit Scatter - ADDED CHECK: Ensure its specific data is ready */}
                      {debitScatterData?.datasets?.[0]?.data?.length > 0 && (
                        <Box width={{xs: '100%', md: '50%'}} sx={{ minHeight: 500 }}>
                          <Scatter
                            data={debitScatterData}
                            options={{ ...debitScatterOptions, maintainAspectRatio: false }}
                          />
                        </Box>
                      )}
                    </Box>
                  )}

                  {/* --- Bar Chart --- */}
                  {/* ADDED CHECK: Ensure data is ready before rendering */}
                  {analysis && fiveDayBinChartData?.labels?.length > 0 && fiveDayBinChartData?.datasets?.[0]?.data?.length > 0 && (
                    <Box mt={4} mb={8} sx={{
                      width: "100%",
                      height: 800,
                      '& canvas': {
                        width: '100% !important',
                        maxWidth: '100%'
                      }
                    }}>
                      <SubTitle variant="h5">
                        Regular Transaction Patterns (5-Day Bins)
                      </SubTitle>
                      <Bar
                        data={{
                          labels: fiveDayBinChartData.labels,
                          datasets: [
                            {
                              label: "Average Income",
                              data: fiveDayBinChartData.data,
                              backgroundColor: "rgba(53, 162, 235, 0.5)",
                              hoverInfo: fiveDayBinChartData.hoverInfo, // Make sure hoverInfo is calculated and exists
                            },
                          ],
                        }}
                        options={{
                          ...fiveDayBinChartOptions,
                          maintainAspectRatio: false,
                          responsive: true,
                          devicePixelRatio: 1, // Consider removing or adjusting
                        }}
                      />
                    </Box>
                  )}

                  <SubTitle variant="h5" gutterBottom sx={{ mt: 8 }}>
                    Гүйлгээний Утга Анализ
                  </SubTitle>
                  <PaginatedTable
                    columns={["#", ...analysisResultsColumns]}
                    data={analysisResultsData}
                    mainWordStyle={mainWordStyle}
                  />

                  <SubTitle variant="h5" gutterBottom sx={{ mt: 4 }}>
                    Сэжигтэй Гүйлгээнүүд
                  </SubTitle>
                  <PaginatedTable
                    columns={filteredDataColumns}
                    data={filteredData}
                  />
                </>
              )}

              {tabValue === 1 && (
                <>
                  {(!transactionAnalysis || Object.keys(transactionAnalysis).length === 0) && ( // Added check for null/undefined
                    <Typography>Давтагдсан Гүйлгээнүүд Олдсонгүй</Typography>
                  )}
                  {/* Added check for transactionAnalysis existence before Object.entries */}
                  {transactionAnalysis && Object.entries(transactionAnalysis)
                    .filter(([accountNumber, value]) => {
                      // Add a check for value validity if needed, e.g., ensure it's an array
                      return accountNumber; // Assuming accountNumber itself is the primary filter
                    }).map(
                    ([account, value], idx) => {
                      // Defensive check: Ensure value is an array with expected structure before destructuring
                      if (!Array.isArray(value) || value.length < 2) {
                          console.warn(`Invalid structure for transactionAnalysis account ${account}:`, value);
                          return <Typography key={idx} color="error">Error displaying data for account {account}.</Typography>;
                      }
                      const [df, perc] = value;
                      // Defensive check: Ensure df is an array before accessing length or keys
                      const accountColumns =
                        Array.isArray(df) && df.length > 0 ? Object.keys(df[0]) : [];
                      return (
                        <Box key={idx} mt={4}>
                          <Typography variant="h6">
                            {/* Ensure perc is a number before calling toFixed */}
                            Account: {account}: {typeof perc === 'number' ? (perc * 100).toFixed(2) : 'N/A'}%
                          </Typography>
                          {/* Ensure df is an array before passing to PaginatedTable */}
                          <PaginatedTable columns={accountColumns} data={Array.isArray(df) ? df : []} />
                        </Box>
                      );
                    }
                  )}
                </>
              )}

              {tabValue === 2 && (
                <>
                  <SubTitle variant="h5">Нийт Хуулга</SubTitle>
                  <PaginatedTable
                    columns={rawTransactionsColumns}
                    data={rawTransactions} // rawTransactions defaults to []
                  />

                  <SubTitle variant="h5">Оройн Гүйлгээ</SubTitle>
                  <PaginatedTable
                    columns={nightTransactionsColumns}
                    data={nightTransactions} // nightTransactions defaults to []
                  />
                </>
              )}
            </>
          )}
        </Container>
      </div>
    </ThemeProvider>
  );
};

export default BankStatementAnalysis;