// FloatingBubbleBackground.js
import React from "react";
import ReactDOM from "react-dom";
import "../styles/FloatingBubbleBackground.css";

const FloatingBubbleBackground = () => {
  return ReactDOM.createPortal(
    <div className="bubble-container">
      <span className="bubble"></span>
      {/* Add more bubbles if desired */}
    </div>,
    document.body
  );
};

export default FloatingBubbleBackground;
