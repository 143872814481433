import React from 'react';
import '../styles/AnimatedHero.css';
import ParticleBackground from './ParticlesBackground';

const AnimatedHero = () => {
  return (
    <div className="animated-hero">
      <ParticleBackground />
      <section className="nav">
        {/* <div className="hero-title">
          <h1>FRONTIER MADE</h1>
          <h1>FOUNDATIONAL</h1>
        </div> */}
        <h3 className="span loader">
          {Array.from('FRONTIERS MADE').map((char, index) => (
            <span key={index} className="m">
              {char === ' ' ? '\u00A0' : char}
            </span>
          ))}
        </h3>
        <h3 className="span loader">
          {Array.from('FOUNDATIONAL').map((char, index) => (
            <span key={index} className="m">
              {char === ' ' ? '\u00A0' : char}
            </span>
          ))}
        </h3>
        <p>Transform groundbreaking research into practical solutions that drive your business forward</p>
        <button className="hero-button">Learn more</button>
      </section>
    </div>
  );
};

export default AnimatedHero; 