// LLMInterface.js
"use client";

import React, { useState, useRef, useEffect } from "react";
import axios from "axios";
import {
  Box,
  TextField,
  Button,
  Typography,
  Paper,
  IconButton,
  Modal,
  Fade,
} from "@mui/material";
import { styled, createTheme, ThemeProvider } from "@mui/material/styles";
import SendIcon from "@mui/icons-material/Send";
import CloseIcon from "@mui/icons-material/Close";
import InfoIcon from "@mui/icons-material/Info";

// Create a dark theme with Rubik font and primary titanium color
const darkTheme = createTheme({
  palette: {
    mode: "dark",
    primary: {
      main: "#989989",
    },
    background: {
      default: "#000000",
      paper: "#000000",
    },
    text: {
      primary: "#ffffff",
    },
  },
  typography: {
    fontFamily: "Rubik, sans-serif",
  },
});

// Main container
const ChatContainer = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  minHeight: "100vh",
  padding: theme.spacing(12, 4, 4, 4),
  color: theme.palette.text.primary,
  position: "relative",
}));

// Chat history area with vertical scrolling.
const ChatHistory = styled(Box)(({ theme }) => ({
  maxHeight: "70vh",
  overflowY: "auto",
  display: "flex",
  flexDirection: "column",
  gap: theme.spacing(1),
  marginBottom: theme.spacing(2),
}));

// Chat bubble for user and bot messages.
const ChatBubble = styled(Paper)(({ theme, sender }) => ({
  padding: theme.spacing(1, 2),
  maxWidth: "80%",
  backgroundColor: sender === "user" ? "#989989" : "#111111",
  color: "#ffffff",
  alignSelf: sender === "user" ? "flex-end" : "flex-start",
  border: "2px solid #989989",
  boxShadow: "0 0 20px #989989, 0 0 30px rgba(152, 152, 137, 0.5)",
  borderRadius: "8px",
}));

// Container for input and send button.
const InputContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  gap: theme.spacing(2),
  alignItems: "center",
}));

// Glowing send button.
const GlowingSendButton = styled(Button)({
  backgroundColor: "#000000",
  color: "#989989",
  border: "2px solid #989989",
  borderRadius: "8px",
  padding: "12px 24px",
  fontWeight: "bold",
  boxShadow: "0 0 20px #989989, 0 0 30px rgba(152, 152, 137, 0.5)",
  textTransform: "none",
  width: "60px",
  height: "60px",
  minWidth: "60px",
  "&:hover": {
    backgroundColor: "#000000",
    boxShadow: "0 0 30px #989989, 0 0 40px rgba(152, 152, 137, 0.6)",
  },
});

// Style for the modal window (similar to your BankStatementAnalysis modal).
const ModalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "80vw",
  maxHeight: "80vh",
  bgcolor: "#000000",
  boxShadow: "0 0 20px #989989, 0 0 30px rgba(152, 152, 137, 0.5)",
  p: 4,
  borderRadius: 2,
  overflowY: "auto",
  color: "#ffffff",
};

// Examples button in the top right corner
const ExamplesButton = styled(Button)(({ theme }) => ({
  position: "absolute",
  top: theme.spacing(12),
  right: theme.spacing(2),
  backgroundColor: "#000000",
  color: "#989989",
  border: "2px solid #989989",
  borderRadius: "8px",
  padding: "8px 16px",
  fontWeight: "bold",
  boxShadow: "0 0 20px #989989, 0 0 30px rgba(152, 152, 137, 0.5)",
  textTransform: "none",
  zIndex: 1000,
  "&:hover": {
    backgroundColor: "#000000",
    boxShadow: "0 0 30px #989989, 0 0 40px rgba(152, 152, 137, 0.6)",
  },
}));

// Styled box for each example question.
const ExampleQuestionBox = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(2),
  marginBottom: theme.spacing(1),
  border: "2px solid #989989",
  borderRadius: "8px",
  boxShadow: "0 0 20px #989989, 0 0 30px rgba(152, 152, 137, 0.5)",
  backgroundColor: "#111111",
  color: "#ffffff",
  cursor: "pointer",
  "&:hover": {
    boxShadow: "0 0 30px #989989, 0 0 40px rgba(152, 152, 137, 0.6)",
  },
}));

// Example questions array (only questions, ignoring reasoning/answer fields).
const exampleQuestions = [
  "Маркетингийн туслах ажлын байранд ярилцлага өгсөн. Гэтэл ажилд ороход “Үгүй ээ, үнэндээ чи ихэвчлэн үйлчлүүлэгчдэд утсаар үйлчлэх болно, маркетинг бол зүгээр л шаардлагатай үед” гэсэн. Энэ... зөвшөөрөгдөх үү? Намайг хуурч мэхэлсэн юм шиг санагдаж байна!",
  "Дарга маань “Бид энд яг гэр бүл шиг!” гэж хэлээд л байнга орой болтол ажиллахыг шаарддаг, олон цагаар, илүү цалингүй. “Гэр бүл бие биедээ тусалдаг” гэнэ. Гэтэл гэрт байгаа гэр бүл маань намайг хармаар байна шүү дээ! “Гэр бүл” гэдэг нь илүү цагийн төлбөргүй байхын шалтаг уу?",
  "Гэнэт ямар ч шалтгаангүйгээр албан тушаал бууруулсан: Би багийн ахлагч байсан, сайн ажилладаг, сайн үнэлгээ авсан. Гэтэл гэнэт л “бүтэц өөрчилж байна” гээд намайг энгийн багийн гишүүн болгосон. Ямар ч тайлбаргүй, сануулгагүй, зүгээр л... үгүй болсон. Ямар ч шалтгаангүйгээр ажлыг бууруулж болох уу?",
  "Татвараас зугтахын тулд намайг “бие даасан гэрээт ажилтан”-аар авсан байх. Гэтэл миний хийх бүх зүйлийг тэд хянадаг! Ажлын цагийг тогтооно, яг яаж хийхийг хэлж өгнө, тоног төхөөрөмжийг нь би ашиглана, бүх зүйл. Би үнэхээр бие даасан гэрээт ажилтан мөн үү, эсвэл намайг зүгээр л тэтгэмжгүй үлдээхийн тулд тэгж нэрлэж байгаа юмуу?",
  "Миний цалин байнга бодож байснаас бага байдаг, цалингийн хуудас дээр “суутгал” гэж бичсэн. Даргаасаа юунд зориулж байгааг асуухад “компанийн зардал” гээд л яриагаа өөрчилсөн. Тэд миний цалингаас дур мэдэн мөнгө авч болох уу?",
  "Би өөрийнхөө гүйцэтгэл зүгээр гэж бодсон. Гэтэл гэнэтхэн гүйцэтгэлийн үнэлгээ муу гарсан, “хүлээлтэд хүрэхгүй байна” гэсэн. Намайг зүгээр л ажлаас халах хэрэг үүсгэх гэж байгаа юм шиг санагдсан! Гүйцэтгэлийн үнэлгээ гэнэтхэн, сөрөг байх нь зүгээр үү?",
  "Ярилцлагын үеэр сайн ажиллавал 6 сарын дараа цалин нэмнэ гэж амаар амласан. 6 сар өнгөрч, цалин нэмэх талаар асуухад намайг ”чи юу яриад байгаа юм” гэсэн! Ярилцлагын үеэр амаар өгсөн амлалт нь үнэхээр ямар нэгэн утга учиртай юу?",
  "Нэг өдөр хурал зарлаад “Уучлаарай, бид цомотгол хийж байна, та бүгдийг ажлаас халж байна, хүчин төгөлдөр болох хугацаа шууд” гэсэн. Сануулгагүй, тэтгэмжгүй, зүгээр л... үгүй. Бүгдийг ямар ч сануулгагүйгээр ажлаас халж болох уу?",
  "Ажилд орохдоо өрсөлдөхгүй байх тухай гэрээнд гарын үсэг зуруулсан. Энэ нь үндсэндээ би ажлаас гарснаас хойш хоёр жилийн турш тухайн салбарт ажиллахгүй гэсэн! Энэ бүрэн хүчинтэй юу? Тэд үнэхээр намайг өөр ажил хийлгэхгүйгээр зогсоож чадах уу?",
  "Би өвчтэй гэж хэлэхэд дарга маань намайг худлаа ярьж байгаа юм шиг аашилсан! Шинж тэмдгийн талаар асууж, нэг өдрийн чөлөөнд эмчийн магадалгаа шаардаж, өвчтэй байгаад минь өөртөө харамсах мэдрэмж төрүүлсэн! Би ийм байцаалтгүйгээр өвчний чөлөө авах эрхтэй юу?",
  "Яг надтай ижил ажил хийдэг, надаас хойно ажилд орсон залуу надаас илүү цалин авч байгааг мэдсэн! Би эмэгтэй хүн болохоор бага цалин авч байгаа юм болов уу. Энэ хууль ёсны дагуу юу? Бид ижил ажилд ижил цалин авах ёстой юу?",
  "Нэг хамт ажиллагч байнга зохисгүй онигоо ярьж, намайг ширтэж, үнэхээр эвгүй мэдрэмж төрүүлдэг. Түүний улмаас ажилд ирэхээс дургүй хүрч байна. Энэ нь “хортой ажлын орчин” гэж тооцогдох уу, би юу хийж болох вэ?"
];

const LLMInterface = () => {
  // State for conversation history.
  const [chatHistory, setChatHistory] = useState([]);
  // State for the current input message.
  const [currentMessage, setCurrentMessage] = useState("");
  // Loading state.
  const [loading, setLoading] = useState(false);
  // State for showing the examples modal.
  const [showExamples, setShowExamples] = useState(false);

  const chatEndRef = useRef(null);

  useEffect(() => {
    chatEndRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [chatHistory, loading]);

  // Refactored sendMessage accepts an optional message parameter.
  const sendMessage = async (msg) => {
    const messageToSend = msg ? msg.trim() : currentMessage.trim();
    if (!messageToSend) return;

    // Append the user message.
    setChatHistory((prev) => [...prev, { sender: "user", text: messageToSend }]);
    if (!msg) setCurrentMessage("");

    setLoading(true);
    try {
      // Make sure your endpoint URL points to your Django backend.
      const response = await axios.post("https://softmax.mn/api/chat/", {
        message: messageToSend,
      });
      if (response.data) {
        // Expecting separate reasoning and answer fields.
        if (response.data.reasoning && response.data.answer) {
          setChatHistory((prev) => [
            ...prev,
            { sender: "bot", text: response.data.reasoning },
            { sender: "bot", text: response.data.answer },
          ]);
        } else if (response.data.text) {
          setChatHistory((prev) => [
            ...prev,
            { sender: "bot", text: response.data.text },
          ]);
        } else {
          setChatHistory((prev) => [
            ...prev,
            { sender: "bot", text: "Error: Unexpected response format." },
          ]);
        }
      }
    } catch (error) {
      console.error("Error in chat API:", error);
      setChatHistory((prev) => [
        ...prev,
        { sender: "bot", text: "Error: Unable to get response." },
      ]);
    }
    setLoading(false);
  };

  // When an example is clicked, send that message and close the modal.
  const handleExampleClick = (question) => {
    setShowExamples(false);
    sendMessage(question);
  };

  // Handle key down for Enter.
  const handleKeyDown = (e) => {
    if (e.key === "Enter" && !e.shiftKey) {
      e.preventDefault();
      sendMessage();
    }
  };

  return (
    <ThemeProvider theme={darkTheme}>
      <ChatContainer>
        {/* "Examples" Button on Top Right */}
        <ExamplesButton onClick={() => setShowExamples(true)}>
          Examples
        </ExamplesButton>

        <Typography variant="h4" sx={{ mb: 4, textAlign: "center" }}>
        Reasoning LLM - Finetuned on Mongolian Labor Law
        </Typography>

        <ChatHistory>
          {chatHistory.map((msg, idx) => (
            <ChatBubble key={idx} sender={msg.sender}>
              <Typography variant="body1" style={{ whiteSpace: "pre-wrap" }}>
                {msg.text}
              </Typography>
            </ChatBubble>
          ))}
          {loading && (
            <ChatBubble sender="bot">
              <Typography variant="body1">Thinking...</Typography>
            </ChatBubble>
          )}
          <div ref={chatEndRef} />
        </ChatHistory>

        <InputContainer>
          <TextField
            fullWidth
            multiline
            maxRows={4}
            variant="outlined"
            placeholder="Type your message..."
            value={currentMessage}
            onChange={(e) => setCurrentMessage(e.target.value)}
            onKeyDown={handleKeyDown}
            InputProps={{
              style: {
                backgroundColor: "#111111",
                color: "#ffffff",
                border: "2px solid #989989",
                borderRadius: "8px",
              },
            }}
          />
          <GlowingSendButton onClick={() => sendMessage()}>
            <SendIcon fontSize="large" />
          </GlowingSendButton>
        </InputContainer>

        {/* Examples Modal */}
        <Modal
          open={showExamples}
          onClose={() => setShowExamples(false)}
          closeAfterTransition
          BackdropProps={{ timeout: 500 }}
        >
          <Fade in={showExamples}>
            <Box sx={ModalStyle}>
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                mb={2}
              >
                <Typography variant="h5">Example Questions</Typography>
                <IconButton onClick={() => setShowExamples(false)}>
                  <CloseIcon sx={{ color: "#ffffff" }} />
                </IconButton>
              </Box>
              {exampleQuestions.map((q, index) => (
                <ExampleQuestionBox
                  key={index}
                  onClick={() => handleExampleClick(q)}
                >
                  <Typography variant="body1" style={{ whiteSpace: "pre-wrap" }}>
                    {q}
                  </Typography>
                </ExampleQuestionBox>
              ))}
            </Box>
          </Fade>
        </Modal>
      </ChatContainer>
    </ThemeProvider>
  );
};

export default LLMInterface;
