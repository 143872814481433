export const brandsData = {
    "Audi": ["A4", "A6", "Q5", "Q7"],
    "BMW": ["3-сери", "4-сери", "5-сери", "6-сери", "7-сери", "X1", "X3", "X5", "X6"],
    "Bongo": ["Bongo"],
    "Changan": ["UNI-K"],
    "Chevrolet": ["Camaro"],
    "Dodge": ["Challenger", "Ram"],
    "Ford": ["Escape", "Everest", "Expedition", "Explorer", "Mustang", "Ranger", "Transit"],
    "Geely": ["Coolray", "Azkarra"],
    "Honda": ["CR-V", "Fit"],
    "Hyundai": ["Grandeur", "Santa-Fe", "Sonata", "Starex"],
    "Infiniti": ["FX", "QX"],
    "Jeep": ["Cherokee", "Grand Cherokee", "Patrol", "Wrangler"],
    "Kia": ["Cadenza/K7", "Optima/K5", "Sportage"],
    "Land Rover": ["Defender", "Discovery", "Range Rover"],
    "Lexus": ["CT", "ES", "GS", "GX", "HS", "IS", "LS", "LX", "NX", "RX"],
    "Mazda": ["CX"],
    "Mazda/CS": ["CS"],
    "Mercedes-Benz": [
      "A-Class", "C-Class", "CLA-Class", "CLS-Class", "E-Class", "G-Class", "GL-Class", "GLA-Class",
      "GLK-Class", "M-Class", "S-Class"
    ],
    "Mercedes-Benz Sprinter": ["Sprinter"],
    "MINI": ["Cooper"],
    "Mitsubishi": ["Delica", "L200", "Outlander", "Pajero", "RVR"],
    "Nissan": [
      "Caravan", "Dualis/Qashqai", "Juke", "Leaf", "Murano", "Navara", "Note", "NV200",
      "Pathfinder", "Patrol", "Serena", "Vanette", "X-Trail"
    ],
    "Porsche": ["Cayenne"],
    "SsangYong": ["Korando", "Rexton"],
    "Subaru": ["Exiga", "Forester", "Impreza", "Legacy", "Outback", "WRX", "XV Crosstrek"],
    "Suzuki": ["Jimny"],
    "Toyota": [
      "4Runner", "Allion", "Alphard", "Aqua", "Auris", "Axio", "Camry", "C-HR", "Corolla",
      "Corolla Cross", "Crown", "Crown Kluger", "Estima", "Esquire", "Fielder", "Fuga",
      "Harrier", "Hiace", "Highlander", "Hilux", "Kluger", "Land Cruiser 100", "Land Cruiser 105",
      "Land Cruiser 200", "Land Cruiser 300", "Land Cruiser 70", "Land Cruiser 77",
      "Land Cruiser 80", "Land Cruiser Prado", "Land Cruiser Prado 120", "Land Cruiser Prado 150",
      "Land Cruiser Prado 95", "Mark", "Noah", "Premio", "Prius 20", "Prius 30", "Prius 35",
      "Prius 40", "Prius 41", "Prius 50", "Prius 51", "Prius 55", "Probox", "Ractis", "RAV4",
      "Rumion", "Sai", "Sienta", "Spade Porte", "Succeed", "Tacoma", "Townace", "Tundra",
      "Vanguard", "Vellfire", "Voxy", "Wish"
    ],
    "UAZ": ["Pickup"],
    "Volkswagen": ["Passat", "Tiguan", "Touareg"]
  }