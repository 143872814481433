import React, { useState, useMemo } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TablePagination,
  TableSortLabel,
} from '@mui/material';
import { styled } from '@mui/material/styles';

// Styled components for dark theme
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  color: '#989989', // Light grey text
  borderColor: '#333333', // Darker border
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: '#1a1a1a', // Dark grey for odd rows
  },
  '&:nth-of-type(even)': {
    backgroundColor: '#242424', // Slightly lighter dark grey for even rows
  },
  '&:hover': {
    backgroundColor: '#2a2a2a', // Slightly lighter on hover
  },
}));

// Comparator function for sorting
const getComparator = (order, orderBy) => {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
};

const descendingComparator = (a, b, orderBy) => {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
};

// Stable sort function
const stableSort = (array, comparator) => {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const cmp = comparator(a[0], b[0]);
    if (cmp !== 0) return cmp;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
};

const PaginatedTable = ({ columns, data, mainWordStyle }) => {
  const [page, setPage] = useState(0);
  const rowsPerPage = 50;

  // Sorting state
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('');

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const sortedData = useMemo(() => {
    if (orderBy) {
      return stableSort(data, getComparator(order, orderBy));
    }
    return data;
  }, [data, order, orderBy]);

  const paginatedData = useMemo(() => {
    return sortedData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
  }, [sortedData, page, rowsPerPage]);

  const defaultMainWordStyle = {
    fontWeight: "bold",
    color: "#ffffff",
  };

  const mergedMainWordStyle = { ...defaultMainWordStyle, ...mainWordStyle };

  const davtagdsanUgnuudKey = "Давтагдсан Үгнүүд";

  return (
    <>
      <TableContainer 
        component={Paper} 
        sx={{ 
          backgroundColor: '#1a1a1a',
          mt: 2,
          '& .MuiTableHead-root': {
            backgroundColor: '#242424',
          },
          '& .MuiTableSortLabel-root': {
            color: '#989989',
            '&.Mui-active': {
              color: '#989989',
            },
            '&:hover': {
              color: '#ffffff',
            },
          },
          '& .MuiTableSortLabel-icon': {
            color: '#989989 !important',
          },
        }}
      >
        <Table>
          <TableHead>
            <TableRow>
              {columns.map((col, idx) => {
                const sortKey = col === '#' ? 'index' : col;
                return (
                  <StyledTableCell key={idx}>
                    {col !== '#' ? (
                      <TableSortLabel
                        active={orderBy === sortKey}
                        direction={orderBy === sortKey ? order : 'asc'}
                        onClick={() => handleRequestSort(sortKey)}
                        sx={{ fontWeight: 'bold' }}
                      >
                        {col}
                      </TableSortLabel>
                    ) : (
                      col
                    )}
                  </StyledTableCell>
                );
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {paginatedData.map((row, rIndex) => (
              <StyledTableRow key={rIndex}>
                {columns.map((col, cIndex) => {
                  const isDavtagdsanUgnuud = col === davtagdsanUgnuudKey;
                  return (
                    <StyledTableCell key={cIndex}>
                      {isDavtagdsanUgnuud ? (
                        <div
                          dangerouslySetInnerHTML={{
                            __html: row[col].replace(
                              /<span class='main-word'>([\s\S]*?)<\/span>/g,
                              `<span style="font-weight: ${mergedMainWordStyle.fontWeight}; color: ${mergedMainWordStyle.color};">$1</span>`
                            ),
                          }}
                        />
                      ) : (
                        typeof row[col] === 'number'
                          ? row[col].toLocaleString()
                          : row[col]
                      )}
                    </StyledTableCell>
                  );
                })}
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        component="div"
        count={data.length}
        page={page}
        onPageChange={handleChangePage}
        rowsPerPage={rowsPerPage}
        rowsPerPageOptions={[]}
        sx={{
          color: '#989989',
          '.MuiTablePagination-toolbar': {
            color: '#989989',
          },
          '.MuiTablePagination-displayedRows': {
            color: '#989989',
          },
          '.MuiTablePagination-actions': {
            color: '#989989',
            '& .MuiIconButton-root': {
              color: '#989989',
            },
          },
        }}
      />
    </>
  );
};

export default PaginatedTable;