import React, { useState } from 'react';
import { TextField, Button, Box, Typography, Snackbar, Alert, CircularProgress } from '@mui/material';
import axios from 'axios'; // Make sure axios is installed

const ContactForm = () => {
  const [formData, setFormData] = useState({
    companyName: '',
    phoneNumber: '',
    email: '',
    message: ''
  });
  
  const [errors, setErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    severity: 'success'
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
    
    // Clear error when user types
    if (errors[name]) {
      setErrors(prev => ({
        ...prev,
        [name]: ''
      }));
    }
  };

  const validateForm = () => {
    const newErrors = {};
    
    if (!formData.companyName.trim()) {
      newErrors.companyName = 'Company name is required';
    }
    
    if (!formData.email.trim()) {
      newErrors.email = 'Email is required';
    } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(formData.email)) {
      newErrors.email = 'Please enter a valid email address';
    }
    
    if (!formData.phoneNumber.trim()) {
      newErrors.phoneNumber = 'Phone number is required';
    }
    
    if (!formData.message.trim()) {
      newErrors.message = 'Message is required';
    }
    
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    if (!validateForm()) {
      return;
    }
    
    setIsSubmitting(true);
    
    try {
      // Send data to backend API
      const response = await axios.post('/api/contact/', formData);
      
      setSnackbar({
        open: true,
        message: 'Thank you for your message! We will get back to you soon.',
        severity: 'success'
      });
      
      // Reset form
      setFormData({
        companyName: '',
        phoneNumber: '',
        email: '',
        message: ''
      });
    } catch (error) {
      console.error('Error submitting form:', error);
      setSnackbar({
        open: true,
        message: error.response?.data?.error || 'Failed to send message. Please try again later.',
        severity: 'error'
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleCloseSnackbar = () => {
    setSnackbar(prev => ({
      ...prev,
      open: false
    }));
  };

  // Rest of your component remains the same...
  
  // Custom styles for form elements to match your site theme
  const inputStyles = {
    '& .MuiOutlinedInput-root': {
      backgroundColor: 'var(--card-background)',
      borderRadius: '8px',
      transition: 'all 0.3s ease',
      '& fieldset': {
        borderColor: 'rgba(255, 255, 255, 0.2)',
      },
      '&:hover fieldset': {
        borderColor: 'var(--primary-color)',
      },
      '&.Mui-focused fieldset': {
        borderColor: 'var(--primary-color)',
        boxShadow: '0 0 10px rgba(152, 153, 137, 0.3)',
      },
    },
    '& .MuiInputLabel-root': {
      color: '#aaa',
    },
    '& .MuiInputLabel-root.Mui-focused': {
      color: 'var(--primary-color)',
    },
    '& .MuiInputBase-input': {
      color: '#989989',
      fontFamily: '"Rubik", "Noto Sans Mongolian", sans-serif',
    },
    marginBottom: '20px',
  };

  return (
    <Box 
      component="form" 
      onSubmit={handleSubmit}
      sx={{
        width: '100%',
        maxWidth: '600px',
        margin: '0 auto',
        padding: '20px',
        fontFamily: '"Rubik", "Noto Sans Mongolian", sans-serif',
      }}
    >
      <TextField
        fullWidth
        label="Company Name"
        name="companyName"
        value={formData.companyName}
        onChange={handleChange}
        error={!!errors.companyName}
        helperText={errors.companyName}
        sx={inputStyles}
      />
      
      <TextField
        fullWidth
        label="Email Address"
        name="email"
        type="email"
        value={formData.email}
        onChange={handleChange}
        error={!!errors.email}
        helperText={errors.email}
        sx={inputStyles}
      />
      
      <TextField
        fullWidth
        label="Phone Number"
        name="phoneNumber"
        value={formData.phoneNumber}
        onChange={handleChange}
        error={!!errors.phoneNumber}
        helperText={errors.phoneNumber}
        sx={inputStyles}
      />
      
      <TextField
        fullWidth
        label="Message"
        name="message"
        multiline
        rows={4}
        value={formData.message}
        onChange={handleChange}
        error={!!errors.message}
        helperText={errors.message}
        sx={inputStyles}
      />
      
      <Button
        type="submit"
        variant="contained"
        disabled={isSubmitting}
        sx={{
          backgroundColor: 'var(--primary-color)',
          color: 'var(--background-color)',
          padding: '12px 24px',
          borderRadius: '8px',
          fontWeight: 'bold',
          fontSize: '1.1rem',
          fontFamily: '"Rubik", "Noto Sans Mongolian", sans-serif',
          textTransform: 'none',
          '&:hover': {
            backgroundColor: '#7a7b6e',
          },
          width: '100%',
          marginTop: '10px',
        }}
      >
        {isSubmitting ? <CircularProgress size={24} color="inherit" /> : 'Send Message'}
      </Button>
      
      <Snackbar 
        open={snackbar.open} 
        autoHideDuration={6000} 
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert 
          onClose={handleCloseSnackbar} 
          severity={snackbar.severity}
          sx={{ width: '100%', fontFamily: '"Rubik", "Noto Sans Mongolian", sans-serif' }}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default ContactForm; 