import React, { useState } from 'react';
import '../styles/Home.css';
import AnimatedHero from './AnimatedHero';
import PartneringCarousel from './PartneringCarousel';
import MotionCards from './MotionCards';
import ContactForm from './ContactForm';

const Home = () => {
  const [showCarousel, setShowCarousel] = useState(true);

  return (
    <div className="home">
      <AnimatedHero />

      <div className="black-section">
        <div className="black-section-content">
          <h2>Who we are</h2>
          <p>
            We bring frontier technologies out of the lab and into your workflow,
            empowering businesses to harness the full potential of artificial intelligence.
            Whether you're looking to optimize processes, unlock new insights, or innovate with confidence,
            we're here to guide you every step of the way—making AI not just a tool for experts,
            but a transformative force for all.
          </p>
        </div>
      </div>

      {/* Motion Cards Section with ID */}
      <div id="motion-cards" className="section-container">
        <MotionCards />
      </div>

      {/* Partnering Section with ID */}
      <div id="partnering-section" className="black-section">
        {showCarousel && <PartneringCarousel />}
      </div>

      {/* Contact Section with ID */}
      <div id="contact-section" className="black-section">
        <div className="black-section-content">
          <h2>Contact Us</h2>
          <p>Get in touch with us to learn more about how we can help your business.</p>
          <ContactForm />
        </div>
      </div>
    </div>
  );
};

export default Home; 