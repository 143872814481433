// src/components/Header.js
import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import '../styles/Header.css';
import { MdMenu, MdClose } from 'react-icons/md';

const navLinks = [
  {
    title: 'Solutions',
    submenu: [
      { title: 'CarGPT', sectionId: 'motion-cards' },
      { title: 'IncomeGPT', sectionId: 'motion-cards' },
      { title: 'LawGPT', sectionId: 'motion-cards' },
    ],
    sectionId: 'motion-cards'
  },
  {
    title: 'Partnering',
    sectionId: 'partnering-section'
  },
  {
    title: 'Contact Us',
    sectionId: 'contact-section'
  }
];

const Header = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [openDropdowns, setOpenDropdowns] = useState({});
  const [isScrolled, setIsScrolled] = useState(false);
  const [activeSection, setActiveSection] = useState('');
  const isHomePage = location.pathname === '/';

  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 0);

      if (location.pathname === '/') {
        const sections = [
          { id: 'motion-cards', navTitle: 'Solutions' },
          { id: 'partnering-section', navTitle: 'Partnering' },
          { id: 'contact-section', navTitle: 'Contact Us' }
        ];
        
        // Find the section closest to the viewport center
        let closestSection = null;
        let closestDistance = Infinity;
        
        for (const section of sections) {
          const element = document.getElementById(section.id);
          if (element) {
            const rect = element.getBoundingClientRect();
            // Calculate distance from the element's center to the viewport center
            const elementCenter = rect.top + rect.height / 2;
            const viewportCenter = window.innerHeight / 2;
            const distance = Math.abs(elementCenter - viewportCenter);
            
            // Only consider sections that are at least partially visible
            if (rect.bottom > 0 && rect.top < window.innerHeight) {
              if (distance < closestDistance) {
                closestDistance = distance;
                closestSection = section.navTitle;
              }
            }
          }
        }
        
        // Only update active section if it's different to avoid unnecessary re-renders
        // If no section is visible (like at the top of the page), set activeSection to empty
        if (closestSection !== activeSection) {
          setActiveSection(closestSection || '');
        }
      }
    };

    // Use a throttled scroll handler for better performance
    let ticking = false;
    const scrollListener = () => {
      if (!ticking) {
        window.requestAnimationFrame(() => {
          handleScroll();
          ticking = false;
        });
        ticking = true;
      }
    };

    // Run the handler once on mount to set initial state
    handleScroll();

    window.addEventListener('scroll', scrollListener);
    return () => window.removeEventListener('scroll', scrollListener);
  }, [location.pathname, activeSection]);

  useEffect(() => {
    if (Object.values(openDropdowns).some(isOpen => isOpen)) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [openDropdowns]);

  const toggleMobileMenu = () => {
    setMobileMenuOpen(!mobileMenuOpen);
  };

  const handleDropdownToggle = (index) => {
    setOpenDropdowns(prev => ({
      ...prev,
      [index]: !prev[index]
    }));
  };

  const handleClickOutside = (event) => {
    if (!event.target.closest('.dropdown')) {
      setOpenDropdowns({});
    }
  };

  const scrollToSection = (sectionId) => {
    // If not on home page, navigate to home page first
    if (!isHomePage) {
      navigate('/');
      // Need to use setTimeout to ensure navigation completes before scrolling
      setTimeout(() => {
        const element = document.getElementById(sectionId);
        if (element) {
          element.scrollIntoView({ 
            behavior: 'smooth',
            block: 'start'
          });
        }
      }, 100);
    } else {
      const element = document.getElementById(sectionId);
      if (element) {
        element.scrollIntoView({ 
          behavior: 'smooth',
          block: 'start'
        });
      }
    }
    
    setMobileMenuOpen(false);
    setOpenDropdowns({});
  };

  const getNavLinkClassName = (title) => {
    return `nav-link ${activeSection === title ? 'active' : ''} ${
      location.pathname === title ? 'active' : ''
    }`;
  };

  const renderNavButton = (item) => (
    <button
      className={getNavLinkClassName(item.title)}
      onClick={() => scrollToSection(item.sectionId)}
    >
      {item.title}
    </button>
  );

  // Render method for Solutions item based on current page
  const renderSolutionsItem = (item, index) => {
    // On home page, show the dropdown
    if (isHomePage) {
      return (
        <>
          <button
            className={getNavLinkClassName(item.title)}
            onClick={() => {
              handleDropdownToggle(index);
              scrollToSection('motion-cards');
            }}
            aria-haspopup="true"
            aria-expanded={openDropdowns[index] || false}
          >
            {item.title}
          </button>
          {openDropdowns[index] && (
            <div className="dropdown-menu dropdown-menu-open" role="menu">
              <div className="dropdown-menu-content">
                {item.submenu.map((subItem, subIndex) => (
                  <button
                    key={`${index}-${subIndex}`}
                    className={`dropdown-item ${location.pathname === subItem.link ? 'active' : ''}`}
                    onClick={() => {
                      handleDropdownToggle(index);
                      scrollToSection(subItem.sectionId);
                    }}
                  >
                    {subItem.title}
                  </button>
                ))}
              </div>
            </div>
          )}
        </>
      );
    } 
    // On other pages, just render a button that navigates to the home page's solutions section
    else {
      return (
        <button
          className={getNavLinkClassName(item.title)}
          onClick={() => scrollToSection(item.sectionId)}
        >
          {item.title}
        </button>
      );
    }
  };

  return (
    <header className={`header ${isScrolled ? 'scrolled' : ''} ${location.pathname === '/' ? 'home-page' : ''}`}>
      {/* Mobile Header */}
      <div className={`mobile-header ${isScrolled ? 'scrolled' : ''} ${location.pathname === '/' ? 'home-page' : ''}`}>
        <div className="mobile-logo">
          <Link to="/">softmax</Link>
        </div>
        <div className="mobile-menu-button">
          <button onClick={toggleMobileMenu} aria-label={mobileMenuOpen ? "Close Menu" : "Open Menu"}>
            {mobileMenuOpen ? <MdClose size={24} /> : <MdMenu size={24} />}
          </button>
        </div>
      </div>

      {/* Mobile Navigation Menu */}
      {mobileMenuOpen && (
        <div className="mobile-menu-overlay" onClick={toggleMobileMenu}>
          <div className="mobile-menu" onClick={(e) => e.stopPropagation()}>
            <nav aria-label="Mobile Navigation">
              <ul>
                {navLinks.map((item, index) => (
                  <li
                    key={index}
                    className={`dropdown ${item.submenu && isHomePage ? 'has-submenu' : ''} ${location.pathname.startsWith(item.link) ? 'active' : ''}`}
                  >
                    {item.submenu && isHomePage ? (
                      <>
                        <button
                          className="nav-link"
                          onClick={() => handleDropdownToggle(index)}
                          aria-haspopup="true"
                          aria-expanded={openDropdowns[index] || false}
                        >
                          {item.title}
                        </button>
                        {openDropdowns[index] && (
                          <div className="dropdown-menu dropdown-menu-open" role="menu">
                            <div className="dropdown-menu-content">
                              {item.submenu.map((subItem, subIndex) => (
                                <button
                                  key={`${index}-${subIndex}`}
                                  className={`dropdown-item ${location.pathname === subItem.link ? 'active' : ''}`}
                                  onClick={() => {
                                    handleDropdownToggle(index);
                                    scrollToSection(subItem.sectionId);
                                  }}
                                >
                                  {subItem.title}
                                </button>
                              ))}
                            </div>
                          </div>
                        )}
                      </>
                    ) : (
                      <button
                        className={`nav-link ${location.pathname === item.link ? 'active' : ''}`}
                        onClick={() => scrollToSection(item.sectionId)}
                      >
                        {item.title}
                      </button>
                    )}
                  </li>
                ))}
              </ul>
            </nav>
          </div>
        </div>
      )}

      {/* Desktop Header */}
      <div className={`desktop-header ${isScrolled ? 'scrolled' : ''}`}>
        <div className="header-content">
          <div className="logo">
            <Link to="/">softmax</Link>
          </div>
          <nav aria-label="Main Navigation">
            <ul>
              {navLinks.map((item, index) => (
                <li
                  key={index}
                  className={`dropdown ${item.submenu && isHomePage ? 'has-submenu' : ''}`}
                >
                  {item.title === 'Solutions' ? 
                    renderSolutionsItem(item, index) : 
                    renderNavButton(item)
                  }
                </li>
              ))}
            </ul>
          </nav>
        </div>
      </div>
    </header>
  );
};

export default Header;