import React from 'react';
import '../styles/MotionCards.css';
import { useNavigate } from 'react-router-dom';
import { motion } from 'framer-motion';

import carImg from '../assets/images/cargpt.png';
import incomeImg from '../assets/images/incomegpt.png';
import lawImg from '../assets/images/lawgpt.png';

const MotionCards = () => {
  const navigate = useNavigate();

  const cardsData = [
    {
      title: 'CarGPT',
      img: carImg,
      route: '/price-estimator',
    },
    {
      title: 'IncomeGPT',
      img: incomeImg,
      route: '/bank-analysis',
    },
    {
      title: 'LawGPT',
      img: lawImg,
      route: '/LawGPT',
    },
  ];

  const cardVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: (i) => ({
      opacity: 1,
      y: 0,
      transition: {
        delay: i * 0.2,
        duration: 0.5,
        ease: "easeOut"
      }
    }),
    hover: {
      y: -10,
      boxShadow: "0 20px 25px rgba(0, 0, 0, 0.3)",
      transition: {
        type: "spring",
        stiffness: 300,
        damping: 20,
        duration: 0.3
      }
    }
  };

  return (
    <div className="motion-cards-section">
      <div className="motion-cards-header">
        <h2>Our Solutions</h2>
        <p>Frontier technologies made accessible for your business</p>
      </div>
      <div className="motion-cards-container">
        {cardsData.map((card, index) => (
          <motion.div
            className="motion-card"
            key={index}
            variants={cardVariants}
            initial="hidden"
            animate="visible"
            custom={index}
          >
            <div className="card-image-container">
              <img src={card.img} alt={card.title} className="card-image" />
              <motion.button
                className="motion-card-button"
                onClick={() => navigate(card.route)}
              >
                Explore
              </motion.button>
            </div>
          </motion.div>
        ))}
      </div>
    </div>
  );
};

export default MotionCards;
