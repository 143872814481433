import React, { useState, useEffect } from 'react';
import {
  TextField,
  Button,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Typography,
  Box,
  Grid,
  Fade,
  Paper,
  Modal,
  ThemeProvider
} from '@mui/material';
import axios from 'axios';
import { brandsData } from '../data/predictioncarData'; // Import the brands and models data
import ParticleBackground from './ParticlesBackground';
import { createTheme } from '@mui/material/styles';

const colorOptions = [
  'Хар', // Black
  'Цагаан', // White
  'Саарал', // Gray
  'Ногоон', // Green
  'Мөнгөлөг', // Light Blue
  'Улаан', // Red
  'Шар', // Yellow
  'Бор', // Brown
  'Бусад' // Other
];

const gearboxOptions = ['Автомат', 'Механик', 'Хагас Автомат'];

const theme = createTheme({
  components: {
    MuiMenu: {
      styleOverrides: {
        paper: {
          backgroundColor: '#0b0b09',
        },
        list: {
          backgroundColor: '#0b0b09',
        }
      }
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          color: '#989989',
          '&:hover': {
            backgroundColor: '#1a1a1a',
          },
          '&.Mui-selected': {
            backgroundColor: '#1a1a1a',
            '&:hover': {
              backgroundColor: '#1a1a1a',
            }
          }
        }
      }
    }
  }
});

const PriceEstimator = () => {
  const currentYear = new Date().getFullYear();

  const [formData, setFormData] = useState({
    brand: '',
    model: '',
    year_made: '',
    imported_year: '',
    odometer: '',
    hurd: '',
    'Хөдөлгүүр': '1.5',
    'Өнгө': '',
    'Хурдны хайрцаг': ''
  });

  const [models, setModels] = useState([]);
  const [predictedPrice, setPredictedPrice] = useState(null);
  const [error, setError] = useState('');
  const [isThinking, setIsThinking] = useState(false);
  const [openModal, setOpenModal] = useState(false);

  // Update models based on selected brand
  useEffect(() => {
    if (formData.brand) {
      const sortedModels = [...brandsData[formData.brand]].sort();
      setModels(sortedModels);
      setFormData((prevData) => ({ ...prevData, model: '' }));
    } else {
      setModels([]);
      setFormData((prevData) => ({ ...prevData, model: '' }));
    }
  }, [formData.brand]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    setPredictedPrice(null); // Reset price when input changes
    setError('');
  };

  const formatPrice = (price) => {
    if (typeof price !== 'number') return 'N/A';
    return price.toLocaleString();
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // 1) Track the button click (analytics)
    try {
      await axios.post('/api/analytics/', {
        event_type: 'button_click',
        path: 'PriceEstimator:Predict',
        ip_address: null
      });
    } catch (err) {
      console.error('Error logging button click:', err);
    }

    if (!formData.year_made || !formData.imported_year) {
      setError('Please enter both Year Made and Imported Year.');
      return;
    }

    setIsThinking(true);
    setPredictedPrice(null);
    setError('');

    const payload = {
      brand: formData.brand,
      model: formData.model,
      year_made: formData.year_made,
      imported_year: formData.imported_year,
      odometer: formData.odometer,
      hurd: formData.hurd,
      'Хурдны хайрцаг': formData['Хурдны хайрцаг'],
      'Хөдөлгүүр': formData['Хөдөлгүүр'],
      'Өнгө': formData['Өнгө'],
    };

    try {
      const response = await axios.post('/api/predict-price/', payload, {
        baseURL: process.env.REACT_APP_API_URL || 'https://softmax.mn',
        headers: {
          'Content-Type': 'application/json',
          'X-API-KEY': ''
        }
      });

      const price = response.data.predicted_price;

      if (price != null && typeof price === 'number') {
        setPredictedPrice(price);
        setOpenModal(true);
      } else {
        throw new Error('Invalid price received from server.');
      }
    } catch (err) {
      console.error('Prediction error:', err);
      setError('Failed to predict price. Please check your inputs and try again.');
    } finally {
      setIsThinking(false);
    }
  };

  // Common styles for TextField / Select (outlined variant)
  const outlinedStyles = {
    '& .MuiOutlinedInput-root': {
      backgroundColor: '#1e1e1e',
      borderRadius: '8px',
      transition: 'all 0.3s ease',
      '& input[type="number"]::-webkit-outer-spin-button, & input[type="number"]::-webkit-inner-spin-button': {
        '-webkit-appearance': 'none',
        margin: 0,
      },
      '& input[type="number"]': {
        '-moz-appearance': 'textfield',
      },
      '& fieldset': {
        borderColor: 'rgba(255, 255, 255, 0.2)',
      },
      '&:hover fieldset': {
        borderColor: '#989989',
        boxShadow: '0 0 10px rgba(152, 153, 137, 0.3)',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#989989',
        boxShadow: '0 0 20px rgba(152, 153, 137, 0.5)',
      },
      '&.Mui-disabled fieldset': {
        borderColor: 'rgba(255, 255, 255, 0.1)',
      },
      // Add styles for the dropdown menu
      '& .MuiSelect-select': {
        color: '#989989',
      },
      '& .MuiMenu-paper': {
        backgroundColor: '#0b0b09',
      },
    },
    '& .MuiInputLabel-root': {
      color: '#aaa',
      fontWeight: 800,
    },
    '& .MuiInputLabel-root.Mui-focused': {
      color: '#989989',
    },
    '& .MuiInputBase-input': {
      color: '#989989',
    },
    // Add styles for MenuItem
    '& .MuiMenuItem-root': {
      backgroundColor: '#0b0b09',
      color: '#989989',
      '&:hover': {
        backgroundColor: '#1a1a1a',
      },
      '&.Mui-selected': {
        backgroundColor: '#1a1a1a',
        '&:hover': {
          backgroundColor: '#1a1a1a',
        }
      }
    }
  };

  // Modal styles
  const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: '#121212',
    border: '1px solid #989989',
    boxShadow: '0 0 24px rgba(152, 153, 137, 0.2)',
    p: 4,
    borderRadius: 2,
    textAlign: 'center'
  };

  return (
    <ThemeProvider theme={theme}>
      <Box
        sx={{
          backgroundColor: '#0b0b09',
          minHeight: '100vh',
          py: 4,
          mt: '60px',
          position: 'relative'
        }}
      >
        {/* Add ParticleBackground */}
        <Box
          sx={{
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            zIndex: 0
          }}
        >
          <ParticleBackground />
        </Box>

        {/* Main content box - add zIndex to display above particles */}
        <Box
          sx={{
            position: 'relative',
            zIndex: 1,
            maxWidth: 600,
            mx: 'auto',
            p: 3,
            borderRadius: 2,
            boxShadow: '0 0 20px rgba(152, 153, 137, 0.05)',
            backgroundColor: '#121212',
            '& *': {
              fontFamily: 'Rubik, sans-serif',
            },
          }}
        >
          <Typography
            variant="h4"
            gutterBottom
            sx={{
              textAlign: 'center',
              fontWeight: 'bold',
              mb: 4,
              background: 'linear-gradient(90deg, #989989 0%, #d9d9d9 100%)',
              WebkitBackgroundClip: 'text',
              WebkitTextFillColor: 'transparent',
            }}
          >
            CarGPT
          </Typography>
          <form onSubmit={handleSubmit}>
            {/* Brand Selection */}
            <FormControl fullWidth required margin="normal" sx={outlinedStyles}>
              <InputLabel id="brand-label">Үйлдвэрлэгч</InputLabel>
              <Select
                labelId="brand-label"
                name="brand"
                value={formData.brand}
                label="Үйлдвэрлэгч"
                onChange={handleChange}
              >
                {Object.keys(brandsData).map((brand) => (
                  <MenuItem key={brand} value={brand} sx={{ fontWeight: 600 }}>
                    {brand}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            {/* Model Selection */}
            <FormControl
              fullWidth
              required
              margin="normal"
              disabled={!formData.brand}
              sx={outlinedStyles}
            >
              <InputLabel id="model-label">Загвар</InputLabel>
              <Select
                labelId="model-label"
                name="model"
                value={formData.model}
                label="Загвар"
                onChange={handleChange}
              >
                {models.map((model) => (
                  <MenuItem key={model} value={model} sx={{ fontWeight: 600 }}>
                    {model}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            {/* Year Made and Imported Year Side by Side */}
            <Grid container spacing={2}>
              {/* Year Made */}
              <Grid item xs={12} sm={6}>
                <TextField
                  label="Үйлдвэрлэсэн он"
                  name="year_made"
                  type="number"
                  value={formData.year_made}
                  onChange={handleChange}
                  fullWidth
                  required
                  margin="normal"
                  inputProps={{ min: 1900, max: currentYear }}
                  sx={outlinedStyles}
                />
              </Grid>

              {/* Imported Year */}
              <Grid item xs={12} sm={6}>
                <TextField
                  label="Орж ирсэн он"
                  name="imported_year"
                  type="number"
                  value={formData.imported_year}
                  onChange={handleChange}
                  fullWidth
                  required
                  margin="normal"
                  inputProps={{ min: 1900, max: currentYear }}
                  sx={outlinedStyles}
                />
              </Grid>
            </Grid>

            {/* Odometer */}
            <TextField
              label="Гүйлт"
              name="odometer"
              type="number"
              value={formData.odometer}
              onChange={handleChange}
              fullWidth
              required
              margin="normal"
              inputProps={{ min: 0 }}
              sx={outlinedStyles}
            />

            {/* Хурдны хайрцаг (Gearbox) */}
            <FormControl fullWidth margin="normal" sx={outlinedStyles}>
              <InputLabel id="hurdny-hairtsag-label">Хурдны хайрцаг</InputLabel>
              <Select
                labelId="hurdny-hairtsag-label"
                name="Хурдны хайрцаг"
                value={formData['Хурдны хайрцаг']}
                label="Хурдны хайрцаг"
                onChange={handleChange}
              >
                {gearboxOptions.map((option) => (
                  <MenuItem key={option} value={option} sx={{ fontWeight: 600 }}>
                    {option}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            {/* Хөдөлгүүр (Engine) */}
            <TextField
              label="Хөдөлгүүр багтаамж (Л)"
              name="Хөдөлгүүр"
              type="number"
              value={formData['Хөдөлгүүр']}
              onChange={handleChange}
              fullWidth
              margin="normal"
              inputProps={{ min: 0, step: 0.1 }}
              sx={outlinedStyles}
            />

            {/* Өнгө (Color) */}
            <FormControl fullWidth margin="normal" sx={outlinedStyles}>
              <InputLabel id="ongoo-label">Өнгө</InputLabel>
              <Select
                labelId="ongoo-label"
                name="Өнгө"
                value={formData['Өнгө']}
                label="Өнгө"
                onChange={handleChange}
              >
                {colorOptions.map((color) => (
                  <MenuItem key={color} value={color} sx={{ fontWeight: 600 }}>
                    {color}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            {/* Hurd */}
            <FormControl fullWidth required margin="normal" sx={outlinedStyles}>
              <InputLabel id="hurd-label">Хүрд</InputLabel>
              <Select
                labelId="hurd-label"
                name="hurd"
                value={formData.hurd}
                label="Хүрд"
                onChange={handleChange}
              >
                <MenuItem value="Буруу">Буруу</MenuItem>
                <MenuItem value="Зөв">Зөв</MenuItem>
              </Select>
            </FormControl>

            {/* Button Container */}
            <Box
              sx={{
                mt: 4,
                display: 'flex',
                justifyContent: 'center',
                minHeight: '48px',
                alignItems: 'center'
              }}
            >
              <Button
                variant="contained"
                type="submit"
                size="large"
                disabled={isThinking}
                sx={{
                  backgroundColor: '#212121',
                  color: '#fff',
                  borderRadius: '8px',
                  boxShadow: '0 0 5px #989989',
                  fontWeight: 900,
                  fontSize: '1.1rem',
                  padding: '12px 30px',
                  '&:hover': {
                    backgroundColor: '#989989',
                  },
                }}
              >
                {isThinking ? 'Ачаалж байна...' : 'Үнэ Тогтоох'}
              </Button>
            </Box>
          </form>
        </Box>

        {/* Remove the inline price display and add Modal */}
        <Modal
          open={openModal}
          onClose={() => setOpenModal(false)}
          aria-labelledby="price-modal"
        >
          <Box sx={modalStyle}>
            <Typography
              variant="h5"
              sx={{
                color: '#ccc',
                mb: 3,
                fontWeight: 600,
                background: 'linear-gradient(90deg, #989989 0%, #d9d9d9 100%)',
                WebkitBackgroundClip: 'text',
                WebkitTextFillColor: 'transparent',
              }}
            >
              Таны машины үнэлгээ
            </Typography>
            <Typography
              variant="h3"
              sx={{
                fontWeight: 600,
                color: '#ffffff',
                mb: 3
              }}
            >
              {formatPrice(predictedPrice)} сая ₮
            </Typography>
            <Button
              onClick={() => setOpenModal(false)}
              sx={{
                backgroundColor: '#989989',
                color: '#000',
                fontWeight: 600,
                '&:hover': {
                  backgroundColor: '#7a7a6d'
                }
              }}
            >
              Хаах
            </Button>
          </Box>
        </Modal>

        {/* Error Message */}
        {error && (
          <Typography color="error" sx={{ mt: 2, textAlign: 'center' }}>
            {error}
          </Typography>
        )}
      </Box>
    </ThemeProvider>
  );
};

export default PriceEstimator;
